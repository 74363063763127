import { useMemo } from 'react';
import { DemeterDataFrequency, RunCalculationEngineResponse } from '../../../../../Generated/Raven-Demeter';
import { IChartDataSeries } from '../../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../../Components/Charts/ChartWrapper/ChartWrapper';
import PriceChartRaw from '../../../../Components/Charts/Price/PriceChartRaw';
import useCurrency from '../../../../Core/Hooks/useCurrencyHook';
import useUnitOfMeasure from '../../../../Core/Hooks/useUnitOfMeasureHook';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { ValorizationStreamRunRequest } from '../ValorizationCalculatorDefinitions';

interface IValorizationStreamPricesChart {
    runCalculationEngineResponses: RunCalculationEngineResponse[];
    valorizationStreamRunRequests: ValorizationStreamRunRequest[];
}

const defaultDataFrequency = DemeterDataFrequency.Monthly;

const ValorizationStreamPricesChart: React.FC<IValorizationStreamPricesChart> = (props: IValorizationStreamPricesChart) => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Currency and unit of measure.
    const [currency] = useCurrency();
    const [unitOfMeasure] = useUnitOfMeasure('General');

    const linesSeries = useMemo<IChartDataSeries[]>(() => {
        if (!props.runCalculationEngineResponses?.length || props.runCalculationEngineResponses.length === 0) {
            return [];
        }

        return props.runCalculationEngineResponses.map((x, index) => {
            const matchingRequest = props.valorizationStreamRunRequests[index];
            const label = translate(matchingRequest.name ?? matchingRequest.displayName ?? x.displayName ?? '');

            return {
                label,
                forecastLabel: `${label} ${translations.text.forwardCurve}`,
                data: x?.results?.map((y) => ({
                    value: y.lines.find((z) => z.displayName === 'Milk Price')?.value,
                    asOfDate: new Date(y.asOfDate),
                    isActualValue: new Date(y?.asOfDate!).getTime() < new Date().getTime(),
                })),
            };
        }) as IChartDataSeries[];
    }, [props.runCalculationEngineResponses]);

    const titleString = useMemo(() => `${translations.calculators.valorization.text.stream} ${translations.words.prices}`, [translations]);

    const dataSourceTag = useMemo(() => translations.dataSource.StoneXCalculations, [translations]);

    const loading = !props.runCalculationEngineResponses;

    return (
        <ChartWrapper name="ValorizationMilkPriceOverTimeChart" title={titleString} dataSourceTag={dataSourceTag} isLoading={loading}>
            <PriceChartRaw
                linesSeries={linesSeries}
                currency={currency}
                unitOfMeasure={unitOfMeasure}
                hidePriceNavigator
                dataFrequency={defaultDataFrequency}
            />
        </ChartWrapper>
    );
};

export default ValorizationStreamPricesChart;
