/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
const RegexValidators = {
    AlphaNumericMinimumLength2: /.{2,}/,
    Email: /^(([^<>()[\]\\.,;:\s@\"+]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    FileExtension: /\.[0-9a-z]+$/i,
    Decimal: /[0-9]+(.[0-9]+)?/,
    Numeric: /[0-9]+/,
    PositiveOrNegativeNumber: /^\s?-?[0-9]?\d*(\.)?(\d+)?$/,
    PositiveNumber: /^[+]?([.]\d+|\d+([.]\d+)?)$/,
    PositiveNumberZeroThroughTen: /^([1-9]|10)$/,
    PositiveNumberZeroThroughOneHundred: /^([1-9]|[1-9][0-9]|100)$/,
    Phone: /(?=(.*\d){8})/,
    Password: /^(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]).{14,}$/,
    SpecialCharacter: /^(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])/,
};

export default RegexValidators;
