/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
import { useEffect, useMemo, useState } from 'react';
import {
    DemeterChartType,
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterLeadingIndicatorModel,
    DemeterRegion,
    DemeterSymbolContractModel,
    DemeterSymbolModel,
    DemeterTableDefinitionType,
} from '../../Generated/Raven-Demeter';
import useSymbolsApi from '../../Refactor/Apis/Hooks/useSymbolsApiHook';
import ProjectionChart from '../../Refactor/Components/Charts/Projection/ProjectionChart';
import { IExchangeCommoditySelection } from '../../Refactor/Components/Navigation/Hooks/useExchangeCommodityNavigationHook';
import useTableDefinition from '../../Refactor/Components/Navigation/Hooks/useTableDefinitionHook';
import FuturesPricesChart from '../../Refactor/Pages/Futures/Prices/FuturesPricesChart';
import { SymbolContractCompositeModel } from '../../Refactor/Pages/Futures/Prices/FuturesPricesTableDefinitions';
import PhysicalPricesChart from '../../Refactor/Pages/PhysicalPrices/PhysicalPricesChart';

export interface IIndicatorModalProps {
    indicatorModel?: DemeterLeadingIndicatorModel;
    showPopout: boolean;
    setShowPopout: (show: boolean) => void;
}

const IndicatorModal: React.FC<IIndicatorModalProps> = (props: IIndicatorModalProps) => {
    const symbolsContracts = useSymbolsApi();
    const [exchangeCommoditySelection, setExchangeCommoditySelection] = useState<IExchangeCommoditySelection>({ exchange: '', commodity: '' });
    const [selectedSymbolContracts, setSelectedSymbolContracts] = useState<SymbolContractCompositeModel[]>([]);
    const [, setDataFrequencyGridData] = useState<any>();
    const [, setDisplayNameForPhysicalPrices] = useState<string>();
    const [showPopout, setShowPopout] = useState<boolean>(false);

    const region = props.indicatorModel?.region ?? DemeterRegion.UnitedStates;
    const commodity = props.indicatorModel?.commodity ?? DemeterCommodity.All;
    const subRegion = props.indicatorModel?.subRegion ?? '';
    const extraParameters = props.indicatorModel?.dataSource ?? '';
    const dataFrequency = '';

    // Custom hooks and state
    const [, tableDefinitionCommodity] = useTableDefinition(DemeterTableDefinitionType.CommodityPricesTable, {
        region,
        commodity,
        subRegion,
        extraParameters,
        dataFrequency: '',
    });

    useEffect(() => {
        setShowPopout(props.showPopout);
    }, [props.showPopout]);

    useEffect(() => {
        const getNewDataFrequencyData = () => {
            const newDataFrequencyData = [
                {
                    display: true,
                    tableDefinitionType: props.indicatorModel?.tableDefinitionType,
                    Currency: props.indicatorModel?.currency,
                    Frequency: props.indicatorModel?.priceFrequency,
                    Name: props.indicatorModel?.displayName,
                    id: props.indicatorModel?.displayName,
                    SubRegion: props.indicatorModel?.subRegion,
                    Unit: props.indicatorModel?.unitOfMeasure,
                    Metric: props.indicatorModel?.unitOfMeasure,
                    currentCommodity: props.indicatorModel?.commodity,
                    Region: props.indicatorModel?.region,
                    data: {
                        dataSource: props.indicatorModel?.dataSource,
                    },
                },
            ];

            return { displayName: props.indicatorModel?.displayName, newDataFrequencyData };
        };

        const data = getNewDataFrequencyData();
        if (data?.displayName && data.newDataFrequencyData) {
            setDisplayNameForPhysicalPrices(data?.displayName);
            setDataFrequencyGridData(data?.newDataFrequencyData);
        }
    }, [props.indicatorModel]);

    useEffect(() => {
        if (!symbolsContracts || !props.indicatorModel?.reutersInstrumentCode) {
            return;
        }

        const symbolModel = symbolsContracts.find((row: DemeterSymbolModel) =>
            row.symbolContracts.some((element: DemeterSymbolContractModel) => element.reutersInstrumentCode === props.indicatorModel?.reutersInstrumentCode),
        );

        const contract = symbolModel?.symbolContracts.find(
            (symbolContract: DemeterSymbolContractModel) => symbolContract.reutersInstrumentCode === props.indicatorModel?.reutersInstrumentCode,
        );

        if (contract) {
            // Don't need the 'selected' property here
            const selectedContract: SymbolContractCompositeModel = {
                id: '0',
                rowIndex: 0,
                multipler: symbolModel!.priceMultiplierFutures,
                reutersInstrumentCodePrefix: symbolModel!.reutersInstrumentCodePrefix,
                ...contract,
            } as SymbolContractCompositeModel;

            setExchangeCommoditySelection({ exchange: symbolModel?.exchange ?? '', commodity: symbolModel?.commodity ?? '' });
            setSelectedSymbolContracts([selectedContract]);
        }
    }, [symbolsContracts, props.indicatorModel]);

    let chartName = props.indicatorModel?.tableDefinitionType.replace('CommodityMonthly', '').replace('Table', '');
    // TODO: Fix this with translations.
    if (chartName?.charAt(chartName.length - 1) !== 's' && chartName?.charAt(chartName.length - 1) !== 'n') {
        chartName = `${chartName}s`;
    }

    const regionCommoditySelections = useMemo(
        () => [
            {
                region,
                subRegion,
                commodity,
                extraParameters,
                dataFrequency,
                currency: props.indicatorModel?.currency!,
                displayName: props.indicatorModel?.displayName ?? '',
            },
        ],

        [region, subRegion, commodity, extraParameters, dataFrequency, extraParameters, props.indicatorModel],
    );

    return (
        <>
            {props.indicatorModel?.chartType === DemeterChartType.Futures && selectedSymbolContracts && (
                <FuturesPricesChart
                    exchangeCommoditySelection={exchangeCommoditySelection}
                    symbolContracts={selectedSymbolContracts}
                    currency={props.indicatorModel?.currency}
                    unitOfMeasure={props.indicatorModel?.unitOfMeasure}
                    showPopout={showPopout}
                    setShowPopout={props.setShowPopout}
                    showOnlyAsPopout
                />
            )}
            {props.indicatorModel?.chartType === DemeterChartType.Value && (
                <ProjectionChart
                    unitOfMeasure={props.indicatorModel.unitOfMeasure}
                    tableDefinitionType={props.indicatorModel.tableDefinitionType}
                    regionCommoditySelection={{ region, subRegion, commodity, extraParameters, dataFrequency }}
                    showPopout={showPopout}
                    setShowPopout={props.setShowPopout}
                    showOnlyAsPopout
                />
            )}
            {props.indicatorModel?.chartType === DemeterChartType.Price && (
                <PhysicalPricesChart
                    title={props.indicatorModel?.displayName}
                    dataFrequency={props.indicatorModel?.priceFrequency as DemeterDataFrequency}
                    tableDefinitionType={props.indicatorModel?.tableDefinitionType}
                    regionCommoditySelections={regionCommoditySelections}
                    displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                    displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 4}
                    currency={props.indicatorModel?.currency}
                    unitOfMeasure={props.indicatorModel?.unitOfMeasure}
                    showPopout={showPopout}
                    setShowPopout={props.setShowPopout}
                    showOnlyAsPopout
                />
            )}
        </>
    );
};

export default IndicatorModal;
