import { Children } from 'react';
import PageLoadingSpinner from '../../LoadingSpinner/PageLoadingSpinner';
import { ApiListResponse } from './ApiListResponse';
import Pagination from './Pagination';
import styles from './TableWrapper.module.scss';

interface ITableWrapperPorps {
    children: JSX.Element;
    apiListResponse?: ApiListResponse;
    showPagination?: boolean;
    handlePagination?: (apiListResponse: ApiListResponse) => void;
    handleTakeCountUpdate?: (apiListResponse: ApiListResponse) => void;
}

const TableWrapper = (props: ITableWrapperPorps) => (
    <div className={styles.companies}>
        {!props.apiListResponse || props.apiListResponse.totalCount === undefined ? (
            <PageLoadingSpinner />
        ) : (
            <div>
                {Children.only(props.children!)}
                <div>
                    {props.showPagination && (
                        <Pagination
                            apiListResponse={props.apiListResponse}
                            handlePagination={props.handlePagination}
                            handleTakeCountUpdate={props.handleTakeCountUpdate}
                        />
                    )}
                </div>
            </div>
        )}
    </div>
);

export default TableWrapper;
