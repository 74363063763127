import React, { useEffect, useRef } from 'react';
import ErrorBoundary from '../../../Core/ErrorHandling/ErrorBoundary';
import { EventActionsEnum, EventCategoriesEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import useSearchParameters from '../Hooks/useSearchParametersHook';
import styles from './Tabs.module.scss';

interface ITabOptions {
    title: string;
    key: string;
    component?: JSX.Element;
    showTab?: boolean;
    testId?: string;
}

interface ITabsProps {
    tabOptions: ITabOptions[];
    isModalTab?: boolean;
    rightSideComponent?: JSX.Element;
}

const Tabs: React.FC<ITabsProps> = (props) => {
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const searchTab = props.isModalTab ? searchParameters.modalTab : searchParameters.tab;
    const currentTabReference = useRef<string | undefined>(searchTab);

    useEffect(() => {
        currentTabReference.current = searchTab;

        if (searchTab) {
            loggingService.trackEventWithAnalytics(EventActionsEnum.Navigation, EventCategoriesEnum.TabChange, searchTab);
            return () => {};
        }

        // If there's no initial tab, set the initial tab to the first tab in the list.
        // This timeout is used to ensure mono doesn't put us in a tab setting loop.
        const timeout = setTimeout(() => {
            if (!currentTabReference.current && !props.isModalTab) {
                setSearchParameters({ tab: props.tabOptions.find((tab) => tab.showTab !== false)?.key ?? '' });
            } else if (!currentTabReference.current) {
                setSearchParameters({ ...searchParameters, modalTab: props.tabOptions.find((tab) => tab.showTab !== false)?.key ?? '' });
            }
        });

        return () => clearTimeout(timeout);
    }, [searchParameters]);

    return (
        <>
            <div className={styles.tab_main_container}>
                {props.tabOptions.length > 0 &&
                    props.tabOptions.map(
                        (tab: ITabOptions) =>
                            tab.showTab !== false && (
                                <button
                                    type="button"
                                    className={tab.key === searchTab ? styles.tab_button_selected : styles.tab_button}
                                    key={`tab_${tab.key}`}
                                    onClick={() => {
                                        if (!props.isModalTab) {
                                            setSearchParameters({ tab: tab.key });
                                        } else {
                                            setSearchParameters({ ...searchParameters, modalTab: tab.key });
                                        }
                                    }}
                                    data-testid={tab.testId}
                                >
                                    {tab.title}
                                </button>
                            ),
                    )}
                {props.rightSideComponent}
                <div className={styles.tab_empty_underline_space} />
            </div>
            <ErrorBoundary>
                <div>{props.tabOptions.find((tab) => tab.showTab !== false && tab.key === searchTab)?.component}</div>
            </ErrorBoundary>
        </>
    );
};

export default Tabs;
