import meanBy from 'lodash.meanby';
import React, { useEffect, useMemo, useState } from 'react';
import { Currency, MarketIndicatorFactorDataModel, UnitOfMeasure } from '../../../../../Generated/Raven-Demeter';
import {
    AverageSeasonalParameters,
    leadingIndicatorTypeDefinitions,
} from '../../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { defaultAverageSeasonalParameters } from '../MarketIndicatorChartDefaultParameters';
import marketIndicatorChartService from '../MarketIndicatorChartService';
import { defaultChartPalette, IMarketIndicatorUserChartBaseProps } from '../MarketIndicatorUserChartDefinitions';
import MarketIndicatorUserChartRaw from '../MarketIndicatorUserChartRaw';
import marketIndicatorAverageSeasonalChartService from './MarketIndicatorAverageSeasonalChartService';

export interface IMarketIndicatorAverageSeasonalChartProps extends IMarketIndicatorUserChartBaseProps {
    parameters?: AverageSeasonalParameters;
}

const separarteAxisThresholdPercent = 0.1;

const MarketIndicatorAverageSeasonalChart: React.FC<IMarketIndicatorAverageSeasonalChartProps> = (props: IMarketIndicatorAverageSeasonalChartProps) => {
    const [translations, translate] = useLanguage();
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);

    useEffect(() => {
        const calculationData = marketIndicatorChartService.filterByYears(chartData, numberOfYears + 1);
        const thisYearData = marketIndicatorChartService.filterByYears(calculationData, 1);
        const seasonalAverageData = marketIndicatorChartService.filterByYears(
            marketIndicatorAverageSeasonalChartService.calculateSeasonalAverageData(calculationData, numberOfYears),
            1,
        );

        const newLineSeries: IChartDataSeries[] = [
            {
                label: translations.text.thisYear,
                data: thisYearData,
            },
            {
                label: `${numberOfYears} ${translations.text.yearlyAverage}`,
                data: seasonalAverageData,
            },
        ];

        // Check if the lines need to be on their own Y-axis.
        const average1 = meanBy(newLineSeries[0].data, 'value');
        const average2 = meanBy(newLineSeries[1].data, 'value');
        if (Math.abs((average1 - average2) / Math.min(Math.abs(average1), Math.abs(average2))) > separarteAxisThresholdPercent) {
            newLineSeries[0].yAxis = 0;
            newLineSeries[1].yAxis = 1;
        }

        setLineSeries(newLineSeries);
    }, [props.runTestMarketIndicatorFactorResponse, props.parameters]);

    const numberOfYears = useMemo(() => {
        const parameters = props.parameters ?? defaultAverageSeasonalParameters;
        const value = Number(parameters.yearsOfData);
        return value;
    }, [props.parameters]);

    const chartData = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.rows &&
            props.runTestMarketIndicatorFactorResponse.rows.length > 0
        ) {
            return marketIndicatorChartService.convertToChartData((props.runTestMarketIndicatorFactorResponse?.rows as MarketIndicatorFactorDataModel[]) ?? []);
        }

        return [];
    }, [props.runTestMarketIndicatorFactorResponse]);

    const isLoading = useMemo(
        () => props.isLoading || !props.runTestMarketIndicatorFactorResponse,
        [props.runTestMarketIndicatorFactorResponse, props.isLoading],
    );

    const title = useMemo(() => {
        if (props.title) {
            return props.title;
        }

        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.commodityDisplayName) {
            const region = props.runTestMarketIndicatorFactorResponse?.subRegion
                ? translate(props.runTestMarketIndicatorFactorResponse?.subRegion)
                : translate(props.runTestMarketIndicatorFactorResponse?.regionDisplayName!);
            const tableDefinitionType =
                translations.tableDefinitionType[
                    leadingIndicatorTypeDefinitions.find((x) => x.value === props.runTestMarketIndicatorFactorResponse?.leadingIndicatorType)
                        ?.tableDefinitionType!
                ];
            const commdityName = props.runTestMarketIndicatorFactorResponse.commodityDisplayName;
            return [region, commdityName, tableDefinitionType].filter((x) => x !== null && x !== '').join(' ');
        }

        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const sourceTag = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags.length > 0
        ) {
            return `${props.runTestMarketIndicatorFactorResponse.dataSourceTags[0]}, ${translations.dataSource.StoneXCalculations}`;
        }

        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const currencies = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.currency) {
            return lineSeries[1]?.yAxis === 1
                ? [props.runTestMarketIndicatorFactorResponse.currency, props.runTestMarketIndicatorFactorResponse.currency]
                : [props.runTestMarketIndicatorFactorResponse.currency];
        }

        return ['' as Currency];
    }, [props.runTestMarketIndicatorFactorResponse, lineSeries]);

    const unitOfMeasures = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.unitOfMeasure) {
            return lineSeries[1]?.yAxis === 1
                ? [props.runTestMarketIndicatorFactorResponse.unitOfMeasure, props.runTestMarketIndicatorFactorResponse.unitOfMeasure]
                : [props.runTestMarketIndicatorFactorResponse.unitOfMeasure];
        }

        return ['' as UnitOfMeasure];
    }, [props.runTestMarketIndicatorFactorResponse, lineSeries]);

    return (
        <ChartWrapper
            name="MarketIndicatorAverageSeasonalChart"
            title={title}
            dataSourceTag={sourceTag}
            isLoading={isLoading}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
        >
            <MarketIndicatorUserChartRaw lineSeries={lineSeries} currencies={currencies} unitOfMeasures={unitOfMeasures} colorPalette={defaultChartPalette} />
        </ChartWrapper>
    );
};

export default MarketIndicatorAverageSeasonalChart;
