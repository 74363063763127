export enum EventActionsEnum {
    AppIssue = 'App Issue',
    AgGridInteraction = 'Ag Grid Interaction',
    ButtonClick = 'Button Click',
    ChartInteraction = 'Chart Interaction',
    ChartMultiselect = 'Chart Multiselect',
    DatePickerInteraction = 'Date Picker Interaction',
    DropdownSelection = 'Dropdown Selection',
    GeneralInfo = 'General Info',
    InputInteraction = 'Input Interaction',
    LoadingError = 'Loading Error',
    ModalInteraction = 'Modal Interaction',
    Navigation = 'Navigation',
    MenuSelection = 'Menu Selection',
    SeasonalChartMultiselect = 'Seasonal Chart Multiselect',
    OutlookSelection = 'Outlook and Reports Link Clicked',
    TableInteraction = 'Table Interaction',
    ToggleClicked = 'Toggle Clicked',
    UserAuthenticated = 'User Passed Authentication',
    UserDenied = 'User Denied',
}

export enum EventCategoriesEnum {
    AddChartRowFromTable = 'Chart Item Added from Table',
    AddedYears = 'Added Years',
    AddedYearsToSeasonalDataTables = 'Added additional years to seasonal tables',
    DropdownClosed = 'Dropdown Closed',
    DropdownSelected = 'Dropdown Selected',
    DropdownOpened = 'Dropdown Opened',
    CarouselNext = 'Carousel Next',
    CarouselPrevious = 'Carousel Previous',
    ChartDownloaded = 'Chart Downloaded',
    CommodityChange = 'Commodity Change',
    CommodityGroupChange = 'Commodity Group Change',
    ComponentLoadingError = 'Component Loading Error',
    DataSourceChange = 'Data Source Change',
    DateTimePickerOpened = 'Date Picker Open',
    DateTimeChanged = 'Date Picker Date Chose',
    DateTimePickerClosed = 'Date Picker Close',
    ExchangeChange = 'Exchange Change',
    FilterTimeSpanSelected = 'Length of time selected',
    ForcastDataToggled = 'Forecast Data Toggled',
    GridClicked = 'Grid Clicked',
    HideOrDisplayData = 'Hide Or Display Data',
    IconMenuSelected = 'Icon Menu Option Selected',
    IconMenuClosed = 'Icon Menu Closed',
    InputValueChanged = 'Input Value Changed',
    JoinedButtonsClicked = 'Joined Button Elements Clicked',
    LanguageChange = 'Language Change',
    LegendItemClicked = 'Legend Item Clicked',
    LocationNotAvailable = 'Location not available!',
    MarketChange = 'Market Change',
    ModalClose = 'Modal Closed',
    ModalSave = 'Modal Saved',
    PopoutChartModalClose = 'Chart Popout Modal Closed',
    PopoutChartModalOpen = 'Chart Popout Modal Opened',
    RegionChange = 'Region Change',
    RemoveChartRowFromTable = 'Chart Item Removed from Table',
    RemovedYearsFromSeasonalDataTables = 'Removed Years From Seasonal Data Tables',
    SubMenuSelection = 'Sub-menu Selection',
    SubRegionChange = 'Sub-region Change',
    Success = 'Success',
    TabChange = 'Tab Change',
    UnauthenticatedUserPageVisit = 'User visited secured page without credentials.',
    UserDeniedLocationPermission = 'User has denied location permission.',
    UserMarketUpdated = 'User Market Updated.',
}

export enum EventDataTargetsEnum {
    BarChart = 'Bar Chart',
    CommodityPriceTable = 'Commodity Price Table',
    ExchangesMenu = 'Exchanges Menu',
    CommodityMenu = 'Commodity Menu',
    CommodityIndicator = 'Commodity Indicator',
    DatePickerInput = 'Date Picker Input',
    ForwardCurveGraph = 'Forward Curve Graph',
    HedgeMonitorPro = 'Hedge Monitor Pro',
    LightStreamerChart = 'Light Streamer Chart',
    LightStreamerGraph = 'Light Streamer Graph',
    MangeModal = 'Manage Modal',
    MarketIndicatorFactor = 'Market Indicator Factor',
    MarketIndicatorChart = 'Market Indicator Chart',
    MultiBarAreaChart = 'Multi Area Chart',
    MultiAreaLineChart = 'Multi Area Line Chart',
    SelectModal = 'Select Modal',
    OutlookLink = 'Outlook Link',
    OriginsAndDestinations = 'Origins and Destinations',
    PhysicalPricesChart = 'Physical Prices Chart',
    PhysicalPricesGraph = 'Physical Prices Graph',
    PhysicalPricesPage = 'Physical Prices Page',
    PriceGraph = 'Price Graph',
    PriceCurveGraph = 'Price Curve Graph',
    ProjectionGraph = 'Projection Graph',
    RegionsAndCommoditiesMenu = 'Regions and Commodities Menu',
    SeasonalChart = 'Seasonal Chart',
    SeasonalGraph = 'Seasonal Graph',
    SeasonalDataTables = 'Seasonal Data Tables',
    SelectionTable = 'Selection Table',
    Sidebar = 'Sidebar',
    SeasonalTable = 'Seasonal Table',
    TextInput = 'Text Input',
}
