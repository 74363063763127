import { useMemo, useState } from 'react';
import { DemeterLeadingIndicatorModel } from '../../../../Generated/Raven-Demeter';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import { ITreeNode } from '../../TreeView/TreeItem';
import ManageProducts from './ManageProducts';
import styles from './ProductsModal.module.scss';
import ProductsModalWrapper from './ProductsModalWrapper';

interface IManageIndicatorsModal {
    title: string;
    selected: DemeterLeadingIndicatorModel[];
    indicators: DemeterLeadingIndicatorModel[];
    open: boolean;
    handleChange: (selectedOptions: DemeterLeadingIndicatorModel[]) => void;
    handleClose: () => void;
}

const ManageIndicatorsModal: React.FC<IManageIndicatorsModal> = (props) => {
    const [translations] = useLanguage();
    const [indicatorNodesSelected, setIndicatorNodesSelected] = useState<ITreeNode[]>([]);

    const treeNodes = useMemo<ITreeNode[]>(() => {
        if (!props.indicators) {
            return [];
        }

        return props.indicators.map((indicator, index) => {
            const indicatorType = languageService.translate(indicator.leadingIndicatorType);
            const displayName = languageService.multiWordTranslate(indicator.displayName);
            const item: ITreeNode = {
                id: indicator.demeterLeadingIndicatorGuid,
                value: `root_${indicatorType}_${displayName}`, // Path of this node realitive to the root.
                name: displayName,
                parentId: `${indicator.leadingIndicatorType}`,
                parentValue: `${indicatorType}`, // Parent node for this node to point to.
                column: props.selected.some((option) => option.demeterLeadingIndicatorGuid === indicator.demeterLeadingIndicatorGuid) ? 'right' : 'left',
                displayOrder: props.selected.findIndex((x) => x.demeterLeadingIndicatorGuid === indicator.demeterLeadingIndicatorGuid),
                order: index,
                expanded: false,
                selectedProduct: false,
                children: [],
            };

            return item;
        });
    }, [props.indicators, props.selected, props.open]);

    const setSelectedIndicators = () => {
        if (!indicatorNodesSelected.length) {
            props.handleChange([]);
            props.handleClose();
            return;
        }

        const allIndicators = props.indicators.map((x) => ({
            displayName: x.displayName,
            guid: x.demeterLeadingIndicatorGuid,
        }));

        const selectedIndicators: DemeterLeadingIndicatorModel[] = indicatorNodesSelected
            .filter((node) => allIndicators.some((x) => x.guid === node.id))
            .map((node) => ({ demeterLeadingIndicatorGuid: allIndicators.find((x) => x.guid === node.id)!.guid } as DemeterLeadingIndicatorModel));

        props.handleChange(selectedIndicators);
        props.handleClose();
    };

    return (
        <ProductsModalWrapper
            name="ManageIndicatorModal"
            dataTarget={EventDataTargetsEnum.MangeModal}
            title={props.title}
            open={props.open}
            subTitle={translations.dashboard.indicators.manageModalSubHeading}
            isSetButtonDisabled={false}
            handleClose={props.handleClose}
            handleSave={setSelectedIndicators}
        >
            <div className={styles.products_modal_content}>
                <ManageProducts
                    productTreeNodes={treeNodes}
                    showSingleOrGroupOption
                    handleSelectedUpdate={setIndicatorNodesSelected}
                    searchPlaceholder={translations.dashboard.indicators.searchIndicatorPlaceholder}
                    availableSubHeader={translations.dashboard.indicators.availableIndicators}
                    selectSubHeader={translations.dashboard.indicators.selectedIndicators}
                />
            </div>
        </ProductsModalWrapper>
    );
};

export default ManageIndicatorsModal;
