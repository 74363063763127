import React, { useMemo } from 'react';
import { MarketIndicatorModel } from '../../../Generated/Raven-Demeter';
import { chartColors } from '../../Components/Charts/ChartDefinitions';
import formattingService from '../../Services/Formatting/FormattingService';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './HedgeMonitorProPage.module.scss';

interface IHedgeMonitorProTableProps {
    marketIndicator?: MarketIndicatorModel;
}

const HedgeMonitorProTable: React.FC<IHedgeMonitorProTableProps> = (props: IHedgeMonitorProTableProps) => {
    // Application hooks and constants.
    const [translations] = useLanguage();

    const marketIndicatorsDecileColor = useMemo(
        () => chartColors.priceDecileColorsRuleSetTwo.find((x) => (x.decileRank - 1) * 10 <= (props.marketIndicator?.priceDecile ?? 0))?.color,
        [props.marketIndicator],
    );

    const contractMonthYear = useMemo(() => {
        if (!props.marketIndicator || !props.marketIndicator.currentPriceContractMonth || !props.marketIndicator.currentPriceContractYear) {
            return '';
        }

        return `${formattingService.toMonthYear(
            new Date(props.marketIndicator.currentPriceContractYear, props.marketIndicator.currentPriceContractMonth - 1, 1),
        )}`;
    }, [props.marketIndicator]);

    return (
        <div className={styles.hedge_monitor_pro_summary_table}>
            <div className={styles.hedge_monitor_pro_summary_table_row}>
                <div className={styles.hedge_monitor_pro_summary_table_header}>
                    {formattingService.toShortDayMonthYear(props.marketIndicator?.currentPriceAsOfDate ?? props.marketIndicator?.asOfDate)}
                </div>
                <div className={styles.hedge_monitor_pro_summary_table_header}>{translations.words.price}</div>
                <div className={styles.hedge_monitor_pro_summary_table_header}>{translations.words.percentile}</div>
            </div>
            <div className={styles.hedge_monitor_pro_summary_table_row}>
                <div className={styles.hedge_monitor_pro_summary_table_header}>{contractMonthYear}</div>
                <div className={styles.hedge_monitor_pro_summary_table_value}>{props.marketIndicator?.currentPrice}</div>
                <div className={styles.hedge_monitor_pro_summary_table_value} style={{ backgroundColor: marketIndicatorsDecileColor }}>
                    {formattingService.toFormattedNumber(props.marketIndicator?.priceDecile, 0)}
                </div>
            </div>
        </div>
    );
};

export default HedgeMonitorProTable;
