import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterCompanyApi, demeterUsersApi } from '../../../../Apis/Apis';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import {
    AddDemeterUserRequest,
    DemeterMarket,
    DemeterUserType,
    MarketPrecision,
    SortDirection,
    UpdateDemeterUserRequest,
} from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserMarkets, selectUserType } from '../../../../Redux/Slices/UserSlice';
import useApi from '../../../Apis/Hooks/useApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Switch from '../../../Components/Form/Buttons/Switch';
import AutoCompleteInput, { AutoCompleteOption } from '../../../Components/Form/Inputs/AutoCompleteInput';
import CheckboxDropdownInput from '../../../Components/Form/Inputs/CheckboxDropdownInput';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import SelectInput, { SelectInputOption } from '../../../Components/Form/Inputs/SelectInput';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import PageHeaderWithBackNavigation from '../../../Components/Headers/PageHeaderWithBackNavigation';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useNotificationHook from '../../../Components/Notifications/useNotificationHook';
import RegexValidators from '../../../Core/Validation/RegexValidators';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import Permissions from '../Permissions/Permissions';
import styles from './UsersPage.module.scss';
import useUserTypeOptions from './useUserTypeOptionsHook';

type ExpirationDateOption = '14' | '30' | '60' | 'custom';

type UpsertDemeterUserRequest = {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    demeterCompanyGuid?: string | null;
    userType?: DemeterUserType;
    expirationDateOption?: ExpirationDateOption | null;
    trialExpirationDate?: string | Date | null;
    subscribedDate?: string | Date | null;
    marketPrecision?: MarketPrecision;
    markets?: DemeterMarket[];
    isCompanyAdministrator?: boolean;
    notes?: string | null;
};

const UserPage: React.FC = () => {
    const { demeterTrialUserGuid } = useParams();
    const navigate = useNavigate();
    const [translations] = useLanguage();
    const [displayError, displaySuccess] = useNotificationHook();
    const [, setSearchParameters] = useSearchParameters();
    const currentUserType = useApplicationSelector(selectUserType);

    // User hooks.
    const [user, setUser] = useState<UpsertDemeterUserRequest>();
    const [, , getUserResponse] = useApi(() => {
        if (demeterTrialUserGuid) {
            return demeterUsersApi.getDemeterUser(demeterTrialUserGuid);
        }

        return null;
    });

    // Company hooks.
    const [companyName, setCompanyName] = useState<string>();
    const [, , listCompaniesResponse] = useApi(() => demeterCompanyApi.listDemeterCompanies(undefined, undefined, SortDirection.Asc, -1, 0));
    const company = useMemo(() => {
        if (!listCompaniesResponse?.rows) {
            return undefined;
        }

        return listCompaniesResponse?.rows?.find((x) => x.name === companyName);
    }, [companyName, listCompaniesResponse]);

    const companyOptions = useMemo((): AutoCompleteOption[] => {
        if (!listCompaniesResponse?.rows) {
            return [];
        }

        return listCompaniesResponse.rows.map((x) => ({
            value: x.demeterCompanyGuid!,
            label: x.name,
            company: x,
        }));
    }, [listCompaniesResponse]);

    // Option hooks.
    const userTypeOptions = useUserTypeOptions(
        false,
        demeterTrialUserGuid ? getUserResponse?.demeterUser?.userType : undefined,
    ) as SelectInputOption<DemeterUserType>[];

    const marketPrecisionOptions = useMemo(
        () => [
            {
                value: null,
                label: '',
            },
            {
                value: MarketPrecision.Realtime,
                label: translations.users.marketPrecision.Realtime,
            },
            {
                value: MarketPrecision.Delayed,
                label: translations.users.marketPrecision.Delayed,
            },
            {
                value: MarketPrecision.EndOfDay,
                label: translations.users.marketPrecision.EndOfDay,
            },
        ],
        [translations],
    ) as { value: MarketPrecision; label: string }[];

    const markets = useApplicationSelector(selectUserMarkets);
    const marketOptions = useMemo(() => {
        const availableMarkets = company?.markets?.sort() ?? markets;
        return availableMarkets.map((x) => ({ value: x, label: translations.market[x] }));
    }, [markets, company]);

    // Actions hooks.
    const [isSaving, setIsSaving] = useState(false);
    // TODO: Perform stronger validation or get the validation from each input.
    const isValid = !!user?.email && !!user.userType && !!user.firstName && !!user.lastName && !!companyName;

    // Load the user if there is one.
    useEffect(() => {
        if (!demeterTrialUserGuid) {
            setUser({ userType: DemeterUserType.Regular, expirationDateOption: '30' });
        } else if (getUserResponse) {
            const responseUser = getUserResponse.demeterUser!;

            setUser({
                ...responseUser,
                expirationDateOption: responseUser.userType === DemeterUserType.Regular ? 'custom' : null,
                trialExpirationDate: responseUser.trialExpirationDate,
                subscribedDate: responseUser.subscribedDate,
                isCompanyAdministrator: responseUser.isCompanyAdministrator,
            });

            setCompanyName(responseUser.companyName ?? undefined);
        }
    }, [demeterTrialUserGuid, getUserResponse]);

    const navigateBackToUsers = () => {
        setSearchParameters({ tab: 'Users' });
        navigate(-1);
    };

    const upsertUser = async (): Promise<void> => {
        const upsertUserRequest = { ...user, userStatus: undefined };

        if (isValid) {
            try {
                if (companyName) {
                    let demeterCompanyGuid = listCompaniesResponse?.rows?.find((x) => x.name === companyName)?.demeterCompanyGuid;

                    if (!demeterCompanyGuid) {
                        const addCompanyResponse = await demeterCompanyApi.addDemeterCompany({ name: companyName });
                        demeterCompanyGuid = addCompanyResponse.data.demeterCompanyGuid;
                    }

                    upsertUserRequest.demeterCompanyGuid = demeterCompanyGuid;
                } else {
                    upsertUserRequest.demeterCompanyGuid = null;
                }

                if (upsertUserRequest.userType === DemeterUserType.Regular) {
                    const now = new Date();
                    if (upsertUserRequest.expirationDateOption === '14') {
                        upsertUserRequest.trialExpirationDate = new Date(now.setDate(now.getDate() + 14)).toUTCString();
                    } else if (upsertUserRequest.expirationDateOption === '30') {
                        upsertUserRequest.trialExpirationDate = new Date(now.setDate(now.getDate() + 30)).toUTCString();
                    } else if (upsertUserRequest.expirationDateOption === '60') {
                        upsertUserRequest.trialExpirationDate = new Date(now.setDate(now.getDate() + 60)).toUTCString();
                    } else if (upsertUserRequest.expirationDateOption === 'custom' && upsertUserRequest.trialExpirationDate) {
                        upsertUserRequest.trialExpirationDate = formattingService.toApiDate(upsertUserRequest.trialExpirationDate);
                    }
                } else {
                    upsertUserRequest.trialExpirationDate = null;
                }

                if (upsertUserRequest.subscribedDate) {
                    upsertUserRequest.subscribedDate = formattingService.toApiDate(upsertUserRequest.subscribedDate);
                } else {
                    upsertUserRequest.subscribedDate = null;
                }

                if (demeterTrialUserGuid) {
                    await demeterUsersApi.updateDemeterUser(demeterTrialUserGuid, upsertUserRequest as UpdateDemeterUserRequest);
                    displaySuccess(translations.users.text.userSuccessfullyUpdated);
                } else {
                    await demeterUsersApi.addDemeterUser(upsertUserRequest as AddDemeterUserRequest);
                    displaySuccess(translations.users.text.userSuccessfullyCreated);
                }

                navigateBackToUsers();
            } catch (error) {
                if (demeterTrialUserGuid) {
                    displayError(translations.users.errors.updateUserError);
                } else {
                    const { response } = error as { response?: { status?: number } };
                    const errorMessage =
                        response?.status === applicationConstants.HttpStatus.Conflict
                            ? translations.users.errors.emailAlreadyExistsError
                            : translations.users.errors.addUserError;
                    displayError(errorMessage);
                }
                setIsSaving(false);
            }
        } else {
            setIsSaving(false);
        }
    };

    const showMarketAccessDropDown = currentUserType === DemeterUserType.Administrator || currentUserType === DemeterUserType.BusinessOwner;

    return (
        <div className={styles.add_edit_user_page_container}>
            <div className={styles.add_edit_user_header_container}>
                <PageHeaderWithBackNavigation
                    handleBackNavigation={navigateBackToUsers}
                    title={demeterTrialUserGuid ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : translations.users.title.add}
                />
            </div>
            <div className={styles.add_edit_user_input}>
                <TextInput
                    title={translations.users.fields.firstName}
                    placeholder={translations.users.fields.firstName}
                    required
                    value={user?.firstName}
                    handleTextChange={(value) => setUser({ ...user, firstName: value })}
                    validation={RegexValidators.AlphaNumericMinimumLength2}
                    errorMessage={translations.users.errors.firstNameRequired}
                />
            </div>
            <div className={styles.add_edit_user_input}>
                <TextInput
                    title={translations.users.fields.lastName}
                    placeholder={translations.users.fields.lastName}
                    required
                    value={user?.lastName}
                    handleTextChange={(value) => setUser({ ...user, lastName: value })}
                    validation={RegexValidators.AlphaNumericMinimumLength2}
                    errorMessage={translations.users.errors.lastNameRequired}
                />
            </div>
            <div className={styles.add_edit_user_input}>
                <TextInput
                    disabled={!!demeterTrialUserGuid}
                    title={translations.users.fields.email}
                    placeholder={translations.users.fields.email}
                    required
                    value={user?.email}
                    handleTextChange={(value) => setUser({ ...user, email: value })}
                    validation={RegexValidators.Email}
                    errorMessage={translations.users.errors.emailInvalid}
                />
            </div>
            <div className={styles.add_edit_user_input}>
                <TextInput
                    title={translations.users.fields.phone}
                    placeholder={translations.users.fields.phone}
                    value={user?.phone}
                    handleTextChange={(value) => setUser({ ...user, phone: value })}
                    validation={RegexValidators.Phone}
                    errorMessage={translations.users.errors.phoneInvalid}
                />
            </div>
            <div className={styles.add_edit_user_input}>
                <AutoCompleteInput
                    title={translations.users.fields.company}
                    labelValue={companyName}
                    options={companyOptions}
                    handleTextChange={(labelValue: string) => setCompanyName(labelValue)}
                    required
                    errorMessage={translations.users.errors.companyNameRequired}
                />
            </div>
            <div className={styles.add_edit_user_input}>
                <p>
                    <Switch checked={user?.isCompanyAdministrator ?? false} handleChange={(value) => setUser({ ...user, isCompanyAdministrator: value })} />
                    {translations.users.fields.companyAdministrator}
                </p>
            </div>
            <div className={styles.add_edit_user_input}>
                <SelectInput
                    title={translations.users.fields.role}
                    value={user?.userType ?? DemeterUserType.Regular}
                    options={userTypeOptions}
                    handleOptionChange={(value: DemeterUserType) => setUser({ ...user, userType: value })}
                />
            </div>
            {user?.userType === DemeterUserType.Regular && (
                <>
                    <div>
                        <input
                            id="trialEndDate14"
                            type="radio"
                            onChange={() => setUser({ ...user, expirationDateOption: '14' })}
                            value="14"
                            checked={user.expirationDateOption === '14'}
                        />
                        <label className={styles.add_edit_user_radio_label} htmlFor="trialEndDate14">
                            {translations.users.trialLength.fourteenDays}
                        </label>
                    </div>
                    <div>
                        <input
                            id="trialEndDate30"
                            type="radio"
                            onChange={() => setUser({ ...user, expirationDateOption: '30' })}
                            value="30"
                            checked={user.expirationDateOption === '30'}
                        />
                        <label className={styles.add_edit_user_radio_label} htmlFor="trialEndDate30">
                            {translations.users.trialLength.thirtyDays}
                        </label>
                    </div>
                    <div>
                        <input
                            id="trialEndDateCustom"
                            type="radio"
                            onChange={() => setUser({ ...user, expirationDateOption: 'custom' })}
                            value="custom"
                            checked={user.expirationDateOption === 'custom'}
                        />
                        <label className={styles.add_edit_user_radio_label} htmlFor="trialEndDateCustom">
                            {translations.users.trialLength.custom}
                        </label>
                    </div>
                    {user?.expirationDateOption === 'custom' && (
                        <div className={styles.add_edit_user_input}>
                            <DatePickerInput
                                name="UserExpirationDate"
                                required
                                value={user?.trialExpirationDate as Date}
                                title={translations.users.fields.expiration}
                                handleDateChange={(value) => setUser({ ...user, trialExpirationDate: value })}
                            />
                        </div>
                    )}
                </>
            )}
            <div className={styles.add_edit_user_input}>
                <DatePickerInput
                    name="UserSubscibeDate"
                    value={user?.subscribedDate as Date}
                    title={translations.users.fields.subscribedDate}
                    handleDateChange={(value) => setUser({ ...user, subscribedDate: value })}
                />
            </div>
            {showMarketAccessDropDown && (
                <div className={styles.add_edit_user_input}>
                    <CheckboxDropdownInput
                        title={translations.users.fields.markets}
                        values={user?.markets ?? []}
                        options={marketOptions}
                        handleOptionSelect={(value: DemeterMarket[]) => setUser({ ...user, markets: [...value] })}
                    />
                </div>
            )}
            <div className={styles.add_edit_user_input}>
                <SelectInput
                    title={translations.users.fields.marketPrecision}
                    value={user?.marketPrecision}
                    options={marketPrecisionOptions}
                    handleOptionChange={(value: MarketPrecision) => setUser({ ...user, marketPrecision: value })}
                />
            </div>
            <div className={styles.add_edit_user_input}>
                <TextInput
                    title={translations.users.fields.notes}
                    placeholder={translations.users.fields.notes}
                    value={user?.notes}
                    handleTextChange={(value) => setUser({ ...user, notes: value })}
                />
            </div>
            {demeterTrialUserGuid && user && (
                <Permissions demeterUserTrialGuid={demeterTrialUserGuid} userType={user?.userType} demeterCompanyGuid={user?.demeterCompanyGuid ?? undefined} />
            )}
            <div className={styles.add_edit_user_submit_action_row}>
                <LinkButton title={translations.actions.cancel} onClick={navigateBackToUsers} type={LinkButtonType.White} />
                <LinkButton
                    title={demeterTrialUserGuid ? translations.actions.save : translations.actions.add}
                    type={LinkButtonType.Blue}
                    onClick={() => {
                        // Set this here and let the async upsertUser do its job.
                        setIsSaving(true);
                        upsertUser();
                    }}
                    disabled={!isValid || isSaving}
                />
            </div>
        </div>
    );
};

export default UserPage;
