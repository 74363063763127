import { useEffect } from 'react';
import { ListCalculationEngineInputsByGroupResponse } from '../../../../../../Generated/Raven-Demeter';
import ActionModal from '../../../../../Components/Modals/ActionModal/ActionModal';
import useSearchParameters from '../../../../../Components/Navigation/Hooks/useSearchParametersHook';
import Tabs from '../../../../../Components/Navigation/Tabs/Tabs';
import useLanguage from '../../../../../Services/Language/useLanguageHook';
import {
    BaseValorizationUpsertRequest,
    CostOfProductionCalculationValues,
    ValorizationRowModel,
    ValorizationStreamRunRequest,
    ValorizationUpsertRequest,
} from '../../ValorizationCalculatorDefinitions';
import styles from '../ValorizationCalculatorActions.module.scss';
import ValorizationBasis from './ValorizationBasisModal';
import ValorizationCostOfProduction from './ValorizationCostOfProductionModal';
import ValorizationYield from './ValorizationYieldModal';

interface IValorizationDataModalProps {
    listCalculationEngineInputsByGroupResponse: ListCalculationEngineInputsByGroupResponse;
    costOfProductionCalculationValues: CostOfProductionCalculationValues;
    setValorizationRow: (setValorizationRow: ValorizationRowModel) => void;
    valorizationStreamRunRequest: ValorizationStreamRunRequest;
    valorizationUpsertRequest: ValorizationUpsertRequest;
    baseValorizationUpsertRequest: BaseValorizationUpsertRequest;
    handleUpdateValorizationUpsertRequest: (valorizationUpsertRequest: ValorizationUpsertRequest, isBaseRequest?: boolean) => void;
    handleUpdateValorizationStreamRunRequests: (newValorizationRunRequest: ValorizationStreamRunRequest, isBaseRequest?: boolean) => void;
    modalValorizationRow: ValorizationRowModel;
    setModalValorizationRow: (modalValorizationRow: ValorizationRowModel) => void;
    showValorizationDataModal: boolean;
    setShowValorizationDataModal: (showSetValorizationModal: boolean) => void;
}

const ValorizationDataModal: React.FC<IValorizationDataModalProps> = (props: IValorizationDataModalProps) => {
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();
    const tabOptions = [
        {
            title: translations.calculators.text.basis,
            key: 'Basis',
            component: <ValorizationBasis modalValorizationRow={props.modalValorizationRow} setModalValorizationRow={props.setModalValorizationRow} />,
            testId: 'ValorizationBasisTab',
        },
        {
            title: translations.calculators.valorization.fields.costOfProduction,
            key: 'CostOfProduction',
            component: (
                <ValorizationCostOfProduction
                    modalValorizationRow={props.modalValorizationRow}
                    setModalValorizationRow={props.setModalValorizationRow}
                    costOfProductionCalculationValues={props.costOfProductionCalculationValues}
                />
            ),
            testId: 'ValorizationCostOfProductionTab',
        },
        {
            title: translations.words.yield,
            key: 'Yield',
            component: <ValorizationYield modalValorizationRow={props.modalValorizationRow} setModalValorizationRow={props.setModalValorizationRow} />,
            testId: 'ValorizationYieldTab',
        },
    ];

    useEffect(() => {
        props.setValorizationRow(props.modalValorizationRow);
    }, [props.valorizationStreamRunRequest]);

    return (
        <ActionModal
            header={props.modalValorizationRow.groupDisplayName}
            showModal={props.showValorizationDataModal}
            actionButtonName={translations.actions.update}
            handleAction={() => {
                const filteredUpsertValueOverrides = { ...props.valorizationUpsertRequest.valueOverrides };
                const filteredStreamRunValueOverrides = { ...props.valorizationStreamRunRequest.valueOverrides };

                delete filteredUpsertValueOverrides[props.costOfProductionCalculationValues.energy?.variableName];
                delete filteredUpsertValueOverrides[props.costOfProductionCalculationValues.labor?.variableName];
                delete filteredUpsertValueOverrides[props.costOfProductionCalculationValues.maintenance?.variableName];
                delete filteredUpsertValueOverrides[props.costOfProductionCalculationValues.depreciation?.variableName];
                delete filteredUpsertValueOverrides[props.costOfProductionCalculationValues.other?.variableName];
                delete filteredStreamRunValueOverrides[props.costOfProductionCalculationValues.energy?.variableName];
                delete filteredStreamRunValueOverrides[props.costOfProductionCalculationValues.labor?.variableName];
                delete filteredStreamRunValueOverrides[props.costOfProductionCalculationValues.maintenance?.variableName];
                delete filteredStreamRunValueOverrides[props.costOfProductionCalculationValues.depreciation?.variableName];
                delete filteredStreamRunValueOverrides[props.costOfProductionCalculationValues.other?.variableName];
                delete filteredUpsertValueOverrides[props.modalValorizationRow.costOfProduction?.variableName!];
                delete filteredStreamRunValueOverrides[props.modalValorizationRow.costOfProduction?.variableName!];

                props.handleUpdateValorizationUpsertRequest({
                    ...props.valorizationUpsertRequest,
                    valueOverrides: { ...filteredUpsertValueOverrides, ...props.modalValorizationRow?.valueOverrides },
                });

                props.handleUpdateValorizationStreamRunRequests({
                    ...props.valorizationStreamRunRequest,
                    valueOverrides: { ...filteredStreamRunValueOverrides, ...props.modalValorizationRow.valueOverrides },
                });

                props.setShowValorizationDataModal(false);
            }}
            secondaryActionButtonName={translations.actions.reset}
            handleSecondaryAction={() => {
                const savedValueOverrides = props.listCalculationEngineInputsByGroupResponse.rows
                    ?.find((x) => x.groupName === searchParameters.groupName)
                    ?.calculationEngineInputs.find(
                        (x) => !props.valorizationStreamRunRequest.overrideName && x.displayName === props.valorizationStreamRunRequest.name,
                    )?.valueOverrides;
                props.handleUpdateValorizationUpsertRequest({ ...props.valorizationUpsertRequest, valueOverrides: {} });
                props.handleUpdateValorizationStreamRunRequests({
                    ...props.valorizationStreamRunRequest,
                    valueOverrides: savedValueOverrides
                        ? { ...props.baseValorizationUpsertRequest.valueOverrides, ...savedValueOverrides }
                        : { ...props.baseValorizationUpsertRequest.valueOverrides },
                });
                props.setShowValorizationDataModal(false);
            }}
            handleCancel={() => props.setShowValorizationDataModal(false)}
        >
            <>
                <p className={styles.valorization_action_how_to_use}>{translations.calculators.valorization.text.howToUseModal}</p>
                <Tabs tabOptions={tabOptions} isModalTab />
            </>
        </ActionModal>
    );
};

export default ValorizationDataModal;
