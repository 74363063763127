import { useState } from 'react';
import { GetCalculationEngineResponse } from '../../../../../../Generated/Raven-Demeter';
import plusSvg from '../../../../../Assets/Icons/expandBlue.svg';
import Dropdown from '../../../../../Components/Form/Inputs/Dropdown';
import TextInput from '../../../../../Components/Form/Inputs/TextInput';
import ActionModal from '../../../../../Components/Modals/ActionModal/ActionModal';
import useSearchParameters from '../../../../../Components/Navigation/Hooks/useSearchParametersHook';
import useCurrency from '../../../../../Core/Hooks/useCurrencyHook';
import useUnitOfMeasure from '../../../../../Core/Hooks/useUnitOfMeasureHook';
import RegexValidators from '../../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../../Services/Language/useLanguageHook';
import { UpsertStreamRequest, ValorizationStreamRunRequest, ValorizationUpsertRequest } from '../../ValorizationCalculatorDefinitions';
import styles from '../ValorizationCalculatorActions.module.scss';

interface IValorizationAddStreamActionProps {
    getCalculationEngineResponses: GetCalculationEngineResponse[];
    streamGroupOptions: { label: string; value: string }[];
    upsertStreamRequest: UpsertStreamRequest;
    setUpsertStreamRequest: (upsertStreamRequest: UpsertStreamRequest) => void;
    getCalculationEngineLoading?: boolean;
    handleUpdateValorizationUpsertRequest: (valorizationUpsertRequest: ValorizationUpsertRequest, isBaseRequest?: boolean) => void;
    handleUpdateValorizationStreamRunRequests: (newValorizationRunRequest: ValorizationStreamRunRequest, isBaseRequest?: boolean) => void;
}

const ValorizationAddStreamAction: React.FC<IValorizationAddStreamActionProps> = (props: IValorizationAddStreamActionProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();

    // Currency and unit of measure.
    const [currency] = useCurrency();
    const [unitOfMeasure] = useUnitOfMeasure('General');

    // Modal display hook.
    const [showAddStreamModalOpen, setShowAddStreamModalOpen] = useState(false);

    const handleStreamRequestAndCloseModal = () => {
        props.setUpsertStreamRequest({ ...props.upsertStreamRequest!, name: '', demeterCalculationEngineGuid: props.streamGroupOptions[0].value });
        setShowAddStreamModalOpen(false);
    };

    return (
        <>
            <div className={styles.valorization_action_add_streams}>
                <button
                    onClick={() => {
                        props.setUpsertStreamRequest({
                            name: '',
                            demeterCalculationEngineGuid: props.streamGroupOptions[0].value,
                            overrideName: undefined,
                            overrideDemeterCalculationEngineGuid: undefined,
                        });
                        setShowAddStreamModalOpen(true);
                    }}
                    type="button"
                >
                    <img alt="#" src={plusSvg} />
                    <p>{translations.calculators.valorization.text.addStream}</p>
                </button>
            </div>
            <ActionModal
                header={translations.calculators.valorization.text.addStream}
                showModal={showAddStreamModalOpen}
                handleCancel={handleStreamRequestAndCloseModal}
                actionButtonName={translations.actions.add}
                actionButtonDisabled={
                    !RegexValidators.AlphaNumericMinimumLength2.test(props.upsertStreamRequest?.name ?? '') || props.getCalculationEngineLoading
                }
                handleAction={() => {
                    props.handleUpdateValorizationUpsertRequest({
                        currency,
                        unitOfMeasure,
                        groupName: searchParameters?.groupName,
                        name: props.upsertStreamRequest?.name!,
                        demeterCalculationEngineGuid: props.upsertStreamRequest?.demeterCalculationEngineGuid!,
                    } as ValorizationUpsertRequest);

                    const currentStreamModel = props.getCalculationEngineResponses?.find(
                        (x) => x.calculationEngine?.demeterCalculationEngineGuid === props.upsertStreamRequest?.demeterCalculationEngineGuid,
                    );

                    props.handleUpdateValorizationStreamRunRequests({
                        ...currentStreamModel?.calculationEngine,
                        ...props.upsertStreamRequest,
                        groupName: searchParameters?.groupName,
                        demeterCalculationEngineInputsGuid: '',
                    } as ValorizationStreamRunRequest);

                    handleStreamRequestAndCloseModal();
                }}
            >
                <>
                    <div className={styles.valorization_action_content_row_full_width}>
                        <TextInput
                            title={translations.calculators.valorization.fields.streamName}
                            placeholder={translations.calculators.valorization.text.enterName}
                            value={props.upsertStreamRequest!.name}
                            handleTextChange={(value) => {
                                props.setUpsertStreamRequest({ ...props.upsertStreamRequest!, name: value });
                            }}
                            required
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                        />
                    </div>
                    <div className={styles.valorization_action_content_row}>
                        <Dropdown
                            label={translations.calculators.valorization.text.stream}
                            value={props.streamGroupOptions!.find((x) => x.value === props.upsertStreamRequest!.demeterCalculationEngineGuid)?.value}
                            options={props.streamGroupOptions}
                            handleOptionChange={(value) => {
                                props.setUpsertStreamRequest({ ...props.upsertStreamRequest!, demeterCalculationEngineGuid: value });
                            }}
                        />
                    </div>
                </>
            </ActionModal>
        </>
    );
};

export default ValorizationAddStreamAction;
