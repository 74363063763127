import React, { useMemo } from 'react';
import { DemeterFeatureType, DemeterPermissionType, DemeterUserType } from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserType } from '../../../Redux/Slices/UserSlice';
import PageHeader from '../../Components/Headers/PageHeader';
import Tabs from '../../Components/Navigation/Tabs/Tabs';
import useFeatureFlag from '../../Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import usePermission from '../../Services/Permissions/usePermissionHook';
import styles from './AdministrationPage.module.scss';
import CompaniesPage from './Companies/CompaniesPage';
import CompanyUsersPage from './Companies/CompanyUsersPage';
import MarketIndicatorsMangementPage from './MarketIndicatorsManagement/MarketIndicatorsManagementPage';
import UsersPage from './Users/UsersPage';
import WorkerLogsPage from './WorkerLogs/WorkerLogsPage';

const AdministrationPage: React.FC = () => {
    const [translations] = useLanguage();
    const pageTitle = translations.administration.title;
    const currentUserType = useApplicationSelector(selectUserType);
    const isPremiumUser = useMemo(() => currentUserType === DemeterUserType.Premium, [currentUserType]);
    const isAdministrator = useMemo(() => currentUserType === DemeterUserType.Administrator, [currentUserType]);

    const tabOptions = [
        {
            title: translations.users.title.list,
            key: 'Users',
            component: <UsersPage />,
            showTab: useFeatureFlag(DemeterFeatureType.Users),
            testId: 'UsersTab',
        },
        {
            title: translations.companies.title.list,
            key: 'Companies',
            component: <CompaniesPage />,
            showTab: useFeatureFlag(DemeterFeatureType.Companies),
            testId: 'CompaniesTab',
        },
        {
            title: translations.marketIndicatorsManagement.title,
            key: 'MarketIndicators',
            component: <MarketIndicatorsMangementPage />,
            showTab:
                useFeatureFlag(DemeterFeatureType.MarketIndicators) &&
                (usePermission(DemeterPermissionType.MarketIndicatorAdministrator) ||
                    usePermission(DemeterPermissionType.MarketIndicatorDeveloper) ||
                    usePermission(DemeterPermissionType.MarketIndicatorCompliance) ||
                    usePermission(DemeterPermissionType.MarketIndicatorSubjectMatterExpert)),
            testId: 'AdministrationCommodityIndicatorManagementTab',
        },
        {
            title: translations.workers.title,
            key: 'Workers',
            component: <WorkerLogsPage />,
            showTab: isAdministrator,
            testId: 'WorkersTab',
        },
    ];

    if (isPremiumUser) {
        return <CompanyUsersPage />;
    }

    return (
        <div className={styles.master_page_container}>
            <PageHeader title={pageTitle} testId="AdministrationPageHeader" />
            <Tabs tabOptions={tabOptions} />
        </div>
    );
};

export default AdministrationPage;
