import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import React from 'react';
import useLanguage from '../../../Services/Language/useLanguageHook';
import LinkButton, { LinkButtonType } from '../../Form/Buttons/LinkButton';
import styles from './ActionModal.module.scss';

interface IActionModalProps {
    header: string;
    showModal: boolean;
    children: JSX.Element;
    actionButtonName?: string;
    secondaryActionButtonName?: string;
    handleAction?: () => void;
    handleSecondaryAction?: () => void;
    handleCancel: () => void;
    actionButtonDisabled?: boolean;
    hideCancel?: boolean;
}

const ActionModal: React.FC<IActionModalProps> = (props: IActionModalProps) => {
    const [translations] = useLanguage();

    return (
        <Modal open={props.showModal} onClose={props.handleCancel}>
            <div className={styles.action_modal_container}>
                <div className={styles.action_modal_modal_header}>
                    <div className={styles.action_modal_modal_header_text}>{props.header}</div>
                    <CloseIcon className={styles.action_modal_alert_modal_close} onClick={props.handleCancel} />
                </div>
                {props.children}
                <div className={styles.action_modal_cancel_set}>
                    {props.actionButtonName && props.handleAction && (
                        <LinkButton
                            disabled={props.actionButtonDisabled}
                            title={props.actionButtonName}
                            type={LinkButtonType.Blue}
                            onClick={props.handleAction}
                        />
                    )}
                    {props.secondaryActionButtonName && props.handleSecondaryAction && (
                        <LinkButton title={props.secondaryActionButtonName} type={LinkButtonType.White} onClick={props.handleSecondaryAction} />
                    )}
                    {!props.hideCancel && <LinkButton title={translations.actions.cancel} type={LinkButtonType.White} onClick={props.handleCancel} />}
                </div>
            </div>
        </Modal>
    );
};

export default ActionModal;
