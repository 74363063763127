import { GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import DataSourceTag from '../../../../Components/DataSourceTag/DataSourceTag';
import {
    CommodityOtcPriceModel,
    Currency,
    DemeterCommodity,
    DemeterRegion,
    DemeterTableDefinitionType,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter';
import useCacheThenApi from '../../../Apis/Hooks/useCacheThenApiHook';
import { IOtcPriceSelection } from '../../../Apis/Hooks/useOtcPricesApiHook';
import Switch from '../../../Components/Form/Buttons/Switch';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import { IRegionCommoditySelection } from '../../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../../Components/Navigation/Hooks/useTableDefinitionHook';
import ErrorBoundary from '../../../Core/ErrorHandling/ErrorBoundary';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import useLanguage from '../../../Services/Language/useLanguageHook';
import OtcPricesChart from './OtcPricesChart';
import OtcPricesForwardCurveChart from './OtcPricesForwardCurveChart';
import styles from './OtcPricesTable.module.scss';
import { CommodityOtcPriceCompositeModel, otcPricesColumnDefinitions, otcPricesColumnOptions, RendererParameters } from './OtcPricesTableDefinitions';

export interface IOtcPricesTableProps {
    regionCommoditySelection: IRegionCommoditySelection;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
}

const defaultSelectedRowIndex = 0;
const selectionLimit = 5;

const OtcPricesTable: React.FC<IOtcPricesTableProps> = (props: IOtcPricesTableProps) => {
    // Text hooks.
    const [, translate] = useLanguage();

    // General definitions and References.
    const gridReference = useRef<AgGridReact>(null);
    const [onGridReady, setOnGridReady] = useState<GridReadyEvent>();
    const commodityOtcPriceCompositeModelsReference = useRef<CommodityOtcPriceCompositeModel[]>([]);
    const region = props.regionCommoditySelection.region as DemeterRegion;
    const commodity = props.regionCommoditySelection.commodity as DemeterCommodity;
    const cacheKey = `${CacheKeys.ListCommodityOtcPricesCurrent}_${region}_${commodity}_${props.currency}_${props.unitOfMeasure}`;

    // Api hooks.
    const [, tableDefinitionCommodity] = useTableDefinition(DemeterTableDefinitionType.CommodityOtcPricesTable, props.regionCommoditySelection);
    const [, , apiResponse] = useCacheThenApi(cacheKey, () => demeterApi.listCommodityOtcPricesCurrent(region, commodity, props.currency, props.unitOfMeasure));

    // Data hooks.
    const [otcPricesSelections, setOtcPricesSelections] = useState<IOtcPriceSelection[]>([]);

    const title = useMemo(() => (tableDefinitionCommodity ? translate(tableDefinitionCommodity?.displayName) : ''), [tableDefinitionCommodity]);

    // We need to create the part of the table definition here because of the local selectRow function.
    const actionsCellRenderer = (parameters: RendererParameters) => (
        <Switch
            disabled={otcPricesSelections?.length === selectionLimit && !parameters.data.selected}
            checked={parameters.data.selected}
            handleChange={() => {
                selectRow(parameters.data.rowIndex, true);
            }}
        />
    );

    const otcPricesColumnDefinitionsWithRenderer: ColDef[] = useMemo(() => {
        const actionField = {
            ...otcPricesColumnDefinitions[otcPricesColumnDefinitions.length - 1],
            cellRenderer: actionsCellRenderer,
        };
        const newOtcPricesColumnDefinition = [...otcPricesColumnDefinitions].slice(0, otcPricesColumnDefinitions.length - 1);

        return [...newOtcPricesColumnDefinition, actionField] as ColDef[];
    }, [otcPricesColumnDefinitions, otcPricesSelections]);

    useEffect(() => {
        if (apiResponse?.rows && apiResponse.rows.length > 0) {
            const rows = apiResponse.rows.map((row: CommodityOtcPriceModel, index: number) => ({
                ...row,
                id: `${index}`,
                rowIndex: index,
                selected: false,
            }));
            commodityOtcPriceCompositeModelsReference.current = rows;
            setOtcPricesSelections([
                {
                    region: region as DemeterRegion,
                    commodity: commodity as DemeterCommodity,
                    contractYear: apiResponse.rows[defaultSelectedRowIndex].contractYear,
                    contractMonth: apiResponse.rows[defaultSelectedRowIndex].contractMonth,
                },
            ]);
            selectRow(defaultSelectedRowIndex);
        }
    }, [apiResponse]);

    useEffect(() => {
        if (!onGridReady && !gridReference?.current?.columnApi && !gridReference?.current?.api) {
            return;
        }

        selectRow(defaultSelectedRowIndex, false, true);
    }, [gridReference?.current, onGridReady]);

    const selectRow = (rowIndex: number, allowMultipleSelections?: boolean, setSelected?: boolean) => {
        const actionFieldName = otcPricesColumnDefinitions[otcPricesColumnDefinitions.length - 1].field ?? '';
        const actionColumn = gridReference.current?.columnApi?.getColumn(actionFieldName);
        const selectedRows = gridReference?.current?.api?.getSelectedRows();
        if (!allowMultipleSelections) {
            (commodityOtcPriceCompositeModelsReference?.current ?? []).forEach((priceModel) => {
                if (priceModel.rowIndex !== rowIndex) {
                    priceModel.selected = false;
                    const rowNode = gridReference?.current?.api.getRowNode(priceModel.id);
                    rowNode?.setSelected(false);
                    if (actionColumn) {
                        rowNode?.setDataValue(actionFieldName, false);
                    }
                }
            });
        }

        const row = commodityOtcPriceCompositeModelsReference?.current[rowIndex];

        if (row) {
            row.selected = !row.selected || (!allowMultipleSelections && (selectedRows?.length ?? 0) > 1) || !!setSelected;
            const selectedRow = gridReference?.current?.api?.getRowNode(row.id);
            selectedRow?.setSelected(row.selected);
            if (actionColumn) {
                selectedRow?.setDataValue(actionFieldName, !!row.selected || (!allowMultipleSelections && (selectedRows?.length ?? 0) > 1));
            }
        }

        commodityOtcPriceCompositeModelsReference.current = [...commodityOtcPriceCompositeModelsReference.current];

        const selections = (commodityOtcPriceCompositeModelsReference.current ?? [])
            .filter((x) => x.selected)
            .map((x) => ({
                region: x.region as DemeterRegion,
                commodity: x.commodity as DemeterCommodity,
                contractYear: x.contractYear,
                contractMonth: x.contractMonth,
            }));
        setOtcPricesSelections(selections);
    };

    return (
        <div className={styles.otc_prices_table_container}>
            {!apiResponse || commodityOtcPriceCompositeModelsReference.current.length === 0 ? (
                <PageLoadingSpinner />
            ) : (
                <>
                    <AgGridBuilder
                        gridRef={gridReference}
                        rowData={commodityOtcPriceCompositeModelsReference.current}
                        hasSaveColumnsState
                        columnDefinitions={otcPricesColumnDefinitionsWithRenderer}
                        defaultColumnDefinition={otcPricesColumnOptions}
                        gridHeightFull
                        domLayout="autoHeight"
                        cellClickedHandler={(event: { colDef: { field: string }; data: CommodityOtcPriceCompositeModel }) => {
                            const actionField = otcPricesColumnDefinitionsWithRenderer[otcPricesColumnDefinitionsWithRenderer.length - 1].field ?? '';
                            if (event.colDef.field !== actionField) {
                                selectRow(+event.data.id);
                            }
                        }}
                        onGridReady={setOnGridReady}
                    />
                    {apiResponse?.dataSourceTag && <DataSourceTag value={apiResponse.dataSourceTag} />}
                    <div className={styles.otc_prices_chart_container}>
                        <ErrorBoundary>
                            <OtcPricesChart
                                title={title}
                                otcPricesSelections={otcPricesSelections}
                                currency={apiResponse!.currency!}
                                unitOfMeasure={apiResponse!.unitOfMeasure!}
                                displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                                displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0}
                            />
                        </ErrorBoundary>
                    </div>
                    <div className={styles.otc_prices_chart_container}>
                        <OtcPricesForwardCurveChart
                            title={title}
                            region={region}
                            commodity={commodity}
                            currency={props.currency!}
                            unitOfMeasure={props.unitOfMeasure!}
                            displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                            displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default OtcPricesTable;
