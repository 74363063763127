import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import ContentType from '../../Core/Common/ContentTypes';
import applicationSettings from '../../Core/Settings/ApplicationSettings';
import NavigationRoutes from '../../Layouts/NavigationRoutes';
import loggingService from './Logging/LoggingService';

/* eslint-disable class-methods-use-this */
class BrowserService {
    private navigate?: NavigateFunction;

    setReactNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    postWithFile = (url: string, request: { [key: string]: string }, file: File) => {
        const formData = new FormData();
        Object.keys(request).forEach((key) => {
            formData.append(key, request[key]);
        });

        formData.append('fileName', file.name);
        formData.append('file', file);
        return axios.post(`${applicationSettings.api?.ravenBackendDemterUri}${url}`, formData, {
            headers: {
                'Content-Type': ContentType.MultipartFormData,
            },
        });
    };

    downloadCsv = async (stringifiedData: string, fileName: string) => {
        const csvBlob = new Blob([stringifiedData], { type: 'text/csv' });
        const url = URL.createObjectURL(csvBlob);
        const link = document.createElement('a');
        link.download = `${fileName}.csv`;
        link.href = url;
        link.click();
        link.remove();
    };

    downloadBlob = (blob: Blob, fileName: string) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    downloadFile = (fileContent: string, fileName: string, contentType: string) => {
        try {
            const blobParts = [Uint8Array.from(atob(fileContent), (c) => c.charCodeAt(0))];
            const blob = new Blob(blobParts, { type: contentType });
            this.downloadBlob(blob, fileName);
        } catch (error) {
            loggingService.trackException(error, `Error while downloding file ${fileName}`);
        }
    };

    navigateToDashboard = (reloadPage: boolean = false) => {
        this.navigate!(NavigationRoutes.Dashboard, { replace: true });
        if (reloadPage) {
            window.location.reload();
        }
    };

    navigateToLoginPage = () => {
        if (applicationSettings.isMono) {
            this.setWindowLocation(applicationSettings.links.monoUri ?? '');
        } else {
            this.navigate!(NavigationRoutes.Home);
        }
    };

    navigateToSessionExpiration = () => {
        this.navigate!(NavigationRoutes.SessionExpiration);
    };

    navigateToMarketIntelligence = () => {
        this.setWindowLocation(applicationSettings.links.marketIntelligenceUri ?? '');
    };

    openMarketIntelligenceArticle = (searchParameters: { language: string | null | undefined; sidebarLabel?: string; articleId?: string }) => {
        window.open(
            `${applicationSettings.links.marketIntelligenceUri}/article-landing?${Object.entries(searchParameters)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')}`,
            '_blank',
        );
    };

    setWindowLocation = (newLocation: string) => window.location.assign(newLocation);

    getLocalStorageItem = (key: string) => localStorage.getItem(`${applicationSettings.applicationName}-${key}`);

    setLocalStorageItem = (key: string, value: string) => localStorage.setItem(`${applicationSettings.applicationName}-${key}`, value);

    deleteLocalStorageItem = (key: string) => localStorage.removeItem(`${applicationSettings.applicationName}-${key}`);
}

const browserService = new BrowserService();

export default browserService;
