import { useOktaAuth } from '@okta/okta-react';
import React, { useMemo } from 'react';
import applicationSettings from '../../../../Core/Settings/ApplicationSettings';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserName } from '../../../../Redux/Slices/UserSlice';
import userProfilePng from '../../../Assets/Icons/userProfile.png';
import useLanguage from '../../../Services/Language/useLanguageHook';
import IconMenuDropdown from '../../Form/Inputs/IconMenuDropdown';
import styles from './ApplicationHeader.module.scss';

const ApplicationHeader: React.FC = () => {
    const [translations] = useLanguage();
    const { oktaAuth } = useOktaAuth();
    const loggedInUser = useApplicationSelector(selectUserName) ?? 'NoUserLoggedIn';

    const dropdownOptions = useMemo(
        () => [
            {
                label: translations.actions.logout,
                value: 'Logout',
            },
        ],
        [translations],
    );

    const handleLogout = () => oktaAuth.signOut({ postLogoutRedirectUri: applicationSettings.okta.postLogoutRedirectUri });

    return (
        <div>
            <div className={styles.logout_container}>
                <IconMenuDropdown
                    name="ApplicationHeader"
                    iconButton={<img className={styles.logout_image} src={userProfilePng} alt="#" />}
                    options={dropdownOptions}
                    handleSelection={handleLogout}
                    messageToDisplayAtTop={loggedInUser}
                    buttonClassName={styles.logout_image_button}
                />
            </div>
            <div />
        </div>
    );
};

export default ApplicationHeader;
