import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useEffect, useState } from 'react';
import { demeterCalculatorsApi } from '../../../../Apis/Apis';
import {
    GetCalculationEngineResponse,
    ListCalculationEngineInputsByGroupResponse,
    RunCalculationEngineResponse,
    RunTestCalculationEngineRequest,
} from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import chartService from '../../../Components/Charts/ChartService';
import IconButton from '../../../Components/Form/Buttons/IconButton';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useCurrency from '../../../Core/Hooks/useCurrencyHook';
import useUnitOfMeasure from '../../../Core/Hooks/useUnitOfMeasureHook';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValorizationCalculator.module.scss';
import {
    BaseValorizationUpsertRequest,
    UpsertStreamRequest,
    ValorizationStreamRunRequest,
    ValorizationUpsertRequest,
} from './ValorizationCalculatorDefinitions';
import ValorizationEditStreamAction from './ValorizationModals/ValorizationRequestActions/ValorizationEditStreamAction';
import ValorizationRow from './ValorizationRow';

interface IValorizationStreamProps {
    showDisableIcon: boolean;
    listCalculationEngineInputsByGroupResponse: ListCalculationEngineInputsByGroupResponse;
    getCalculationEngineResponses: GetCalculationEngineResponse[];
    valorizationStreamRunRequest: ValorizationStreamRunRequest;
    valorizationUpsertRequest: ValorizationUpsertRequest;
    upsertStreamOptions: { label: string; value: string }[];
    upsertStreamRequest?: UpsertStreamRequest;
    baseValorizationUpsertRequest: BaseValorizationUpsertRequest;
    setBaseValorizationUpsertRequest: (baseValorizationUpsertRequest: BaseValorizationUpsertRequest) => void;
    setUpsertStreamRequest: (upsertStreamRequest: UpsertStreamRequest) => void;
    handleUpdateValorizationUpsertRequest: (valorizationUpsertRequest: ValorizationUpsertRequest, isBaseRequest?: boolean) => void;
    handleUpdateValorizationStreamRunRequests: (newValorizationRunRequest: ValorizationStreamRunRequest, isBaseRequest?: boolean) => void;
}

const ValorizationStream: React.FC<IValorizationStreamProps> = (props: IValorizationStreamProps) => {
    // Application hooks.
    const [, translate] = useLanguage();
    const [searchParameters] = useSearchParameters();

    // Currency and unit of measure.
    const [currency, setCurrency] = useCurrency();
    const [unitOfMeasure, setUnitOfMeasure] = useUnitOfMeasure('General');

    // Display hooks.
    const [disableStream, setDisableStream] = useState<boolean>();

    // Api hooks.
    const [runCalculationEngineLoading, refreshRunCalculationEngine, runCalculationEngineResponse] = useApi<
        RunTestCalculationEngineRequest,
        RunCalculationEngineResponse
    >((request) => demeterCalculatorsApi.runTestCalculationEngine(props.valorizationStreamRunRequest?.demeterCalculationEngineGuid!, request), {
        stopAutoExecute: true,
    });

    // Run the engine when the composite model changes.
    useEffect(() => {
        if (!props.valorizationStreamRunRequest) {
            return;
        }

        refreshRunCalculationEngine({
            demeterCalculationEngineGuid: props.valorizationStreamRunRequest?.demeterCalculationEngineGuid!,
            dataOverrides: {},
            valueOverrides: { ...props.valorizationStreamRunRequest?.valueOverrides },
            monthlyValueOverrides:
                props.valorizationStreamRunRequest?.monthlyValueOverrides?.length > 0 ? [...props.valorizationStreamRunRequest.monthlyValueOverrides!] : [],
        });
    }, [
        props.valorizationStreamRunRequest?.demeterCalculationEngineGuid,
        props.valorizationStreamRunRequest?.monthlyValueOverrides,
        props.valorizationStreamRunRequest?.valueOverrides,
    ]);

    useEffect(() => {
        if (currency && unitOfMeasure) {
            return;
        }

        if (!currency) {
            setCurrency(runCalculationEngineResponse?.currency);
        }

        if (!unitOfMeasure) {
            setUnitOfMeasure(runCalculationEngineResponse?.unitOfMeasure);
        }
    }, [runCalculationEngineResponse?.currency, runCalculationEngineResponse?.unitOfMeasure]);

    // When we hit the 'X' button on a stream.
    useEffect(() => {
        if (!disableStream) {
            return;
        }

        props.handleUpdateValorizationStreamRunRequests({
            name: props.valorizationStreamRunRequest.name,
            demeterCalculationEngineInputsGuid: props.valorizationStreamRunRequest.demeterCalculationEngineInputsGuid,
            isDeleted: true,
        } as ValorizationStreamRunRequest);

        props.handleUpdateValorizationUpsertRequest({ ...props.valorizationUpsertRequest, isDeleted: true } as ValorizationUpsertRequest, false);
    }, [disableStream]);

    // Only needs to run one time to set up the initial baseValorizationUpsertRequest.
    useEffect(() => {
        const initialBaseValuesAreSet =
            props.baseValorizationUpsertRequest?.fatPercent &&
            props.baseValorizationUpsertRequest?.proteinPercent &&
            props.baseValorizationUpsertRequest?.otherPercent;

        if (initialBaseValuesAreSet && props.baseValorizationUpsertRequest.groupName === searchParameters.groupName) {
            return;
        }

        const mostRecentData = runCalculationEngineResponse?.results?.findLast((y) => new Date(y.asOfDate).getTime() < new Date().getTime())?.lines;
        const fatPercent = mostRecentData?.find((y) => y.displayName === 'Fat %');
        const proteinPercent = mostRecentData?.find((y) => y.displayName === 'Protein %');
        const otherPercent = mostRecentData?.find((y) => y.displayName === 'Other %');

        const newBaseValorizationRequest = {
            ...props.baseValorizationUpsertRequest,
            groupName: searchParameters.groupName !== 'default' ? searchParameters.groupName : '',
            fatPercent,
            proteinPercent,
            otherPercent,
            valueOverrides: {} as { [key: string]: number },
        };

        // We get each key of the possible value overrides. If they exist, we add them to the base object.
        const keysOfValueOverrides = Object.keys(props.valorizationStreamRunRequest.valueOverrides ?? {});

        if (keysOfValueOverrides.includes(fatPercent?.variableName!)) {
            newBaseValorizationRequest.valueOverrides[fatPercent!.variableName] = props.valorizationStreamRunRequest.valueOverrides[fatPercent!.variableName];
        }

        if (keysOfValueOverrides.includes(proteinPercent?.variableName!)) {
            newBaseValorizationRequest.valueOverrides[proteinPercent!.variableName] =
                props.valorizationStreamRunRequest.valueOverrides[proteinPercent!.variableName];
        }

        if (keysOfValueOverrides.includes(otherPercent?.variableName!)) {
            newBaseValorizationRequest.valueOverrides[otherPercent!.variableName] =
                props.valorizationStreamRunRequest.valueOverrides[otherPercent!.variableName];
        }

        props.setBaseValorizationUpsertRequest({
            ...newBaseValorizationRequest,
            valueOverrides: {
                ...(props.baseValorizationUpsertRequest?.groupName === searchParameters.groupName ? props.baseValorizationUpsertRequest?.valueOverrides : {}),
                ...newBaseValorizationRequest?.valueOverrides,
            },
        } as BaseValorizationUpsertRequest);
    }, [runCalculationEngineResponse]);

    const loading = !runCalculationEngineResponse;

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className={styles.valorization_calculator_stream}>
            <div className={styles.valorization_calculator_stream_title_area}>
                <div className={styles.valorization_calculator_stream_summary_row}>
                    <p>{props.valorizationUpsertRequest?.name!}</p>
                    {props.showDisableIcon && (
                        <IconButton handleClick={() => setDisableStream(true)}>
                            <HighlightOffIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>
                <p>{translate(props.valorizationStreamRunRequest?.displayName ?? '')}</p>
                <ValorizationEditStreamAction
                    getCalculationEngineResponses={props.getCalculationEngineResponses}
                    upsertStreamOptions={props.upsertStreamOptions}
                    upsertStreamRequest={props.upsertStreamRequest!}
                    setUpsertStreamRequest={props.setUpsertStreamRequest}
                    valorizationUpsertRequest={props.valorizationUpsertRequest}
                    valorizationStreamRunRequest={props.valorizationStreamRunRequest}
                    handleUpdateValorizationUpsertRequest={props.handleUpdateValorizationUpsertRequest}
                    handleUpdateValorizationStreamRunRequests={props.handleUpdateValorizationStreamRunRequests}
                />
            </div>
            <div className={styles.valorization_calculator_stream_rows_area}>
                {props.valorizationStreamRunRequest?.calculationEngineLines
                    ?.filter((x) => x.commodity)
                    .map((x) => (
                        <ValorizationRow
                            key={`${x.displayName}_${x.demeterCalculationEngineLineGuid}`}
                            listCalculationEngineInputsByGroupResponse={props.listCalculationEngineInputsByGroupResponse}
                            runCalculationEngineResponse={runCalculationEngineResponse}
                            calculationEngineLineModel={x}
                            valorizationUpsertRequest={props.valorizationUpsertRequest}
                            valorizationStreamRunRequest={props.valorizationStreamRunRequest}
                            baseValorizationUpsertRequest={props.baseValorizationUpsertRequest}
                            handleUpdateValorizationUpsertRequest={props.handleUpdateValorizationUpsertRequest}
                            handleUpdateValorizationStreamRunRequests={props.handleUpdateValorizationStreamRunRequests}
                        />
                    ))}
            </div>
            <div
                className={
                    runCalculationEngineLoading ? styles.valorization_calculator_stream_final_price_updating : styles.valorization_calculator_stream_final_price
                }
            >
                <div className={styles.valorization_calculator_stream_price}>
                    {formattingService.toPriceString(
                        runCalculationEngineResponse?.results
                            ?.findLast((y) => new Date(y.asOfDate).getTime() < new Date().getTime())
                            ?.lines?.find((y) => y.displayName === 'Milk Price')?.value ?? 0,
                    )}
                </div>
                <div className={styles.valorization_calculator_stream_currency_unit}>
                    {chartService.getCurrencyAndUnitOfMeasureText(runCalculationEngineResponse.unitOfMeasure, runCalculationEngineResponse.currency)}
                </div>
            </div>
        </div>
    );
};

export default ValorizationStream;
