import axios from 'axios';
import applicationSettings from '../Core/Settings/ApplicationSettings';
import {
    DemeterApi,
    DemeterCalculatorsApi,
    DemeterCompaniesApi,
    DemeterDomesticsApi,
    DemeterDownloadsApi,
    DemeterExportsApi,
    DemeterImportsApi,
    DemeterMarketIndicatorsApi,
    DemeterOtherApi,
    DemeterProductionApi,
    DemeterRegistrationApi,
    DemeterStocksApi,
    DemeterUserDataApi,
    DemeterUsersApi,
    DemeterWorkerSyncStatusesApi,
} from '../Generated/Raven-Demeter/api';
import { Configuration } from '../Generated/Raven-Demeter/configuration';
import { FuturesApi, MarketIntelligenceApi, UsersApi } from '../Generated/Raven/api';

export type ApiError = Error & { response: { data?: { errors: string[]; detail: string }; status: number } };

const demeterApi = new DemeterApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterCalculatorsApi = new DemeterCalculatorsApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterCompanyApi = new DemeterCompaniesApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterDomesticsApi = new DemeterDomesticsApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterDownloadsApi = new DemeterDownloadsApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterExportsApi = new DemeterExportsApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterImportsApi = new DemeterImportsApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterMarketIndicatorsApi = new DemeterMarketIndicatorsApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterOtherApi = new DemeterOtherApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterProductionApi = new DemeterProductionApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterStocksApi = new DemeterStocksApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterRegistrationApi = new DemeterRegistrationApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterUserDataApi = new DemeterUserDataApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterUsersApi = new DemeterUsersApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const demeterWorkerSyncStatusesApi = new DemeterWorkerSyncStatusesApi(new Configuration(), applicationSettings.api?.ravenBackendDemterUri, axios);
const futuresApi = new FuturesApi(new Configuration(), applicationSettings.api?.ravenBackendUri, axios);
const marketIntelligenceApi = new MarketIntelligenceApi(new Configuration(), applicationSettings.api?.ravenBackendUri, axios);
const usersApi = new UsersApi(new Configuration(), applicationSettings.api?.ravenBackendUri, axios);

export {
    demeterApi,
    demeterCalculatorsApi,
    demeterCompanyApi,
    demeterDomesticsApi,
    demeterDownloadsApi,
    demeterProductionApi,
    demeterStocksApi,
    demeterImportsApi,
    demeterExportsApi,
    demeterMarketIndicatorsApi,
    demeterOtherApi,
    demeterRegistrationApi,
    demeterUserDataApi,
    demeterUsersApi,
    demeterWorkerSyncStatusesApi,
    futuresApi,
    marketIntelligenceApi,
    usersApi,
};
