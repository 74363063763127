import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { demeterCalculatorsApi } from '../../../../Apis/Apis';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { DemeterCalculatorType } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import useCacheThenApi from '../../../Apis/Hooks/useCacheThenApiHook';
import PageHeaderWithBackNavigation from '../../../Components/Headers/PageHeaderWithBackNavigation';
import TableWrapper from '../../../Components/Tables/TableWrapper/TableWrapper';
import CacheKeys from '../../../Services/Cache/CacheKeys';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ValorizationCalculator.module.scss';

const CalculatorsValorizationListPage: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();
    const navigate = useNavigate();

    // Api hooks.
    const [, , listCalculationEngineInputsByGroupResponse] = useCacheThenApi(CacheKeys.ListCalculationEngineInputsByGroup, () =>
        demeterCalculatorsApi.listCalculationEngineInputsByGroup(DemeterCalculatorType.Valorization),
    );

    const valorizationModelsOptions = useMemo(() => {
        if (!listCalculationEngineInputsByGroupResponse) {
            return [];
        }

        const options = [
            {
                id: 'default',
                modelName: (
                    <div>
                        {translations.words.standard} ({translations.words.default})
                    </div>
                ),
                savedBy: translations.words.system,
                lastUpdated: applicationConstants.TablePlaceholderZeroOrEmpty,
                actions: <div>{translations.words.edit}</div>,
            },
        ];

        listCalculationEngineInputsByGroupResponse.rows!.forEach((x) => {
            options.push({
                id: x.groupName,
                modelName: <Link to={`${NavigationRoutes.Calculators}?tab=Valorization&groupName=${x.groupName}`}>{translate(x.groupName)}</Link>,
                savedBy: x.modifiedBy ?? applicationConstants.TablePlaceholderZeroOrEmpty,
                lastUpdated: x.modifiedAt ? formattingService.toShortDayMonthYear(new Date(x.modifiedAt)) : applicationConstants.TablePlaceholderZeroOrEmpty,
                actions: <Link to={`${NavigationRoutes.Calculators}?tab=Valorization&groupName=${x.groupName}`}>{translations.words.edit}</Link>,
            });
        });

        return options;
    }, [translations, listCalculationEngineInputsByGroupResponse]);

    const listCalculationEngineInputsByGroupResponseWithDefaultModel = useMemo(
        () => ({
            ...listCalculationEngineInputsByGroupResponse,
            totalCount: (listCalculationEngineInputsByGroupResponse?.totalCount ?? 0) + 1,
            take: applicationConstants.MaximumTake,
        }),
        [listCalculationEngineInputsByGroupResponse],
    );

    // TODO: add pagination.
    const handlePagination = () => {};

    const handleTakeCountUpdate = () => {};

    const navigateBackToValorization = () => {
        navigate(-1);
    };

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithBackNavigation
                handleBackNavigation={navigateBackToValorization}
                title={translations.calculators.valorization.text.valorizationModels}
            />
            <div className={styles.valorization_list_table}>
                <TableWrapper
                    apiListResponse={listCalculationEngineInputsByGroupResponseWithDefaultModel}
                    showPagination
                    handlePagination={handlePagination}
                    handleTakeCountUpdate={handleTakeCountUpdate}
                >
                    <>
                        <div className={styles.valorization_list_row}>
                            <div className={styles.valorization_list_header_cell}>{translations.calculators.valorization.text.modelName}</div>
                            <div className={styles.valorization_list_header_cell}>{translations.text.savedBy}</div>
                            <div className={styles.valorization_list_header_cell}>{translations.text.lastUpdated}</div>
                            <div className={styles.valorization_list_header_cell}>{translations.words.actions}</div>
                        </div>

                        {valorizationModelsOptions.map((x) => (
                            <div key={x.id} className={styles.valorization_list_row}>
                                <div className={styles.valorization_list_model_name}>{x.modelName}</div>
                                <div className={styles.valorization_list_saved_by}>{x.savedBy}</div>
                                <div className={styles.valorization_list_last_updated}>{x.lastUpdated}</div>
                                <div className={styles.valorization_list_actions}>{x.actions}</div>
                            </div>
                        ))}
                    </>
                </TableWrapper>
            </div>
        </div>
    );
};

export default CalculatorsValorizationListPage;
