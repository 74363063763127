import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import JoinedSelectionButtons from '../../Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import TextInput from '../../Form/Inputs/TextInput';
import { ITreeNode } from '../../TreeView/TreeItem';
import TreeView from '../../TreeView/TreeView';
import styles from './ProductsModal.module.scss';
import productsModalService from './ProductsModalService';

type SingleOrGroup = 'single' | 'group';

interface IManageProducts {
    productTreeNodes: ITreeNode[];
    showSingleOrGroupOption: boolean;
    handleSelectedUpdate: (nodes: ITreeNode[]) => void;
    searchPlaceholder: string;
    selectSubHeader: string;
    availableSubHeader: string;
}

const ManageProducts: React.FC<IManageProducts> = (props) => {
    const [translations] = useLanguage();
    const treeNodeReference = useRef<ITreeNode[]>(props.productTreeNodes);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [useJoinedButtonsSingle, setUseJoinedButtonsSingle] = useState(true);
    const [treeNodes1, setTreeNodes1] = useState<ITreeNode[]>([]);
    const [treeNodes2, setTreeNodes2] = useState<ITreeNode[]>([]);

    const handleSearch = (search: string) => {
        setSearchTerm(search);
    };

    const moveHighlightedUpOrDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        const sortedRightColumnNodes: ITreeNode[] = [...treeNodeReference.current]
            .filter((x) => x.column === 'right')
            .sort((a, b) => a.displayOrder - b.displayOrder);
        const isUpArror = event.currentTarget.id === 'ArrowUp';
        const highlightedProducts = sortedRightColumnNodes.filter((node) => node.selectedProduct);
        if (!sortedRightColumnNodes.length || !highlightedProducts.length) {
            return;
        }

        const updatedNodeOrder = highlightedProducts.reduce(
            (rightSideNodes, product) => {
                const currentIndex = sortedRightColumnNodes.findIndex((x) => x.id === product.id);

                if (isUpArror && currentIndex > 0) {
                    const previousProduct: ITreeNode = rightSideNodes[currentIndex - 1];
                    [product.displayOrder, previousProduct.displayOrder] = [previousProduct.displayOrder, product.displayOrder];
                }

                if (!isUpArror && currentIndex < rightSideNodes.length - 1) {
                    const next: ITreeNode = rightSideNodes[currentIndex + 1];
                    [product.displayOrder, next.displayOrder] = [next.displayOrder, product.displayOrder];
                }

                return rightSideNodes.sort((a, b) => a.displayOrder - b.displayOrder);
            },
            [...sortedRightColumnNodes],
        );

        const leftSideNodes = [...treeNodeReference.current].filter((x) => x.column === 'left');
        treeNodeReference.current = [...leftSideNodes, ...updatedNodeOrder];
        setTreeNodes2([...updatedNodeOrder]);
    };

    const moveHighlightedLeftOrRight = (event: React.MouseEvent<HTMLButtonElement>) => {
        const isRightArrow = event.currentTarget.id === 'ArrowRight';
        const newTreeNodes = [...treeNodeReference.current]
            .sort((a, b) => {
                if (a.selectedProduct) {
                    return 1;
                }

                if (b.selectedProduct) {
                    return -1;
                }

                return 0;
            })
            .map((node) => {
                if (node.selectedProduct) {
                    const item = { ...node };
                    item.column = isRightArrow ? 'right' : 'left';
                    item.selectedProduct = false;
                    return item;
                }

                return node;
            });

        const leftSideNodes = newTreeNodes.filter((x) => x.column === 'left').sort((a, b) => a.order - b.order);
        const rightSideNodes = newTreeNodes
            .filter((x) => x.column === 'right')
            .map((x, i) => {
                const item = { ...x };
                item.displayOrder = i;
                return item;
            })
            .sort((a, b) => a.displayOrder - b.displayOrder);

        treeNodeReference.current = [...leftSideNodes, ...rightSideNodes];
        setTreeNodes1(useJoinedButtonsSingle ? leftSideNodes : productsModalService.buildTree(leftSideNodes)?.children);
        setTreeNodes2(rightSideNodes);
    };

    const handleSelect1 = (event: React.MouseEvent<HTMLElement>) => {
        const rightSideNodes = treeNodeReference.current.filter((x) => x.column === 'right');
        const leftSideNodes = treeNodeReference.current.filter((x) => x.column === 'left');
        const newSelected = leftSideNodes.find((x) => x.id === event.currentTarget.id);
        if (!newSelected) {
            return;
        }

        const selectedLeftNodes = leftSideNodes.filter((x) => x.selectedProduct);
        if (event.ctrlKey) {
            leftSideNodes.forEach((x) => {
                if (newSelected.id === x.id) {
                    x.selectedProduct = true;
                }
            });
        } else if (event.shiftKey) {
            const previous = selectedLeftNodes[selectedLeftNodes.length - 1].order || 0;
            const lower = Math.min(previous, newSelected.order || 0);
            const upper = Math.max(previous, newSelected.order || 0);
            leftSideNodes.forEach((x) => {
                if (x.order >= 0 && x.order >= lower && x.order <= upper) {
                    x.selectedProduct = true;
                }
            });
        } else {
            leftSideNodes.forEach((x) => {
                if (newSelected.id === x.id) {
                    x.selectedProduct = true;
                } else {
                    x.selectedProduct = false;
                }
            });
        }

        treeNodeReference.current = [...leftSideNodes, ...rightSideNodes];
        setTreeNodes1(useJoinedButtonsSingle ? leftSideNodes : productsModalService.buildTree(leftSideNodes)?.children);
    };

    const handleSelect2 = (event: React.MouseEvent<HTMLElement>) => {
        const rightSideNodes = treeNodeReference.current.filter((x) => x.column === 'right');
        const leftSideNodes = treeNodeReference.current.filter((x) => x.column === 'left');
        const newSelected = rightSideNodes.find((x) => x.id === event.currentTarget.id);
        if (!newSelected) {
            return;
        }

        const selectedRightNodes = rightSideNodes.filter((x) => x.selectedProduct);
        if (event.ctrlKey) {
            rightSideNodes.forEach((x) => {
                if (newSelected.id === x.id) {
                    x.selectedProduct = true;
                }
            });
        } else if (event.shiftKey) {
            const previous = selectedRightNodes[selectedRightNodes.length - 1].displayOrder || 0;
            const lower = Math.min(previous, newSelected.displayOrder || 0);
            const upper = Math.max(previous, newSelected.displayOrder || 0);
            rightSideNodes.forEach((x) => {
                if (x.displayOrder >= 0 && x.displayOrder >= lower && x.displayOrder <= upper) {
                    x.selectedProduct = true;
                }
            });
        } else {
            rightSideNodes.forEach((x) => {
                if (newSelected.id === x.id) {
                    x.selectedProduct = true;
                } else {
                    x.selectedProduct = false;
                }
            });
        }

        treeNodeReference.current = [...leftSideNodes, ...rightSideNodes];
        setTreeNodes2(rightSideNodes);
    };

    const selectionGroupOptions: { label: string; value: SingleOrGroup }[] = useMemo(
        () => [
            {
                label: translations.dashboard.indicators.group,
                value: 'group',
            },
            {
                label: translations.dashboard.indicators.single,
                value: 'single',
            },
        ],
        [],
    );

    const updateJoinedSelection = (selectionValue: SingleOrGroup) => {
        setUseJoinedButtonsSingle(selectionValue === 'single');
    };

    useEffect(() => {
        if (!treeNodeReference.current) {
            return;
        }

        const availableNodes = [...treeNodeReference.current].filter((x) => x.column === 'left').sort((a, b) => a.order - b.order);
        const selectedNodes = [...treeNodeReference.current].filter((x) => x.column === 'right').sort((a, b) => a.displayOrder - b.displayOrder);
        treeNodeReference.current = [...availableNodes, ...selectedNodes];
        setTreeNodes1(useJoinedButtonsSingle ? availableNodes : productsModalService.buildTree(availableNodes)?.children);
        setTreeNodes2(selectedNodes);
    }, [useJoinedButtonsSingle]);

    useEffect(() => {
        props.handleSelectedUpdate(treeNodes2);
    }, [treeNodes2]);

    return (
        <div className={styles.products_modal_body}>
            <div className={styles.products_modal_manage_input_row}>
                {props.showSingleOrGroupOption && (
                    <JoinedSelectionButtons
                        name={EventDataTargetsEnum.MangeModal}
                        options={selectionGroupOptions}
                        handleSelectionChange={updateJoinedSelection}
                        selection={useJoinedButtonsSingle ? 'single' : 'group'}
                    />
                )}

                <TextInput
                    value={searchTerm}
                    className={styles.products_modal_manage_input_text}
                    placeholder={props.searchPlaceholder}
                    handleTextChange={handleSearch}
                />
            </div>

            <div className={styles.products_modal_tree_selection_row}>
                <div className={styles.products_modal_product_column}>
                    <div className={styles.products_modal_product_column_subheader_text}>{props.availableSubHeader}</div>

                    <div className={styles.products_modal_tree_container}>
                        {treeNodes1 && <TreeView data={treeNodes1} searchTerm={searchTerm} handleSelect={handleSelect1} />}
                    </div>
                </div>

                <div className={styles.products_modal_left_right_buttons}>
                    <button id="ArrowLeft" onClick={moveHighlightedLeftOrRight} className={styles.products_modal_movement_button} type="button">
                        <ChevronLeftIcon className={styles.products_modal_arrow_icons} />
                    </button>
                    <button id="ArrowRight" onClick={moveHighlightedLeftOrRight} className={styles.products_modal_movement_button} type="button">
                        <ChevronRightIcon className={styles.products_modal_arrow_icons} />
                    </button>
                </div>

                <div className={styles.products_modal_product_column}>
                    <div className={styles.products_modal_product_column_subheader_text}>{props.selectSubHeader}</div>

                    <div className={styles.products_modal_tree_container}>
                        {treeNodes2 && <TreeView data={treeNodes2} searchTerm="" handleSelect={handleSelect2} />}
                    </div>
                </div>

                <div className={styles.products_modal_left_right_buttons}>
                    <button id="ArrowUp" onClick={moveHighlightedUpOrDown} className={styles.products_modal_movement_button} type="button">
                        <KeyboardArrowUpIcon className={styles.products_modal_arrow_icons} />
                    </button>
                    <button id="ArrowDown" onClick={moveHighlightedUpOrDown} className={styles.products_modal_movement_button} type="button">
                        <KeyboardArrowDownIcon className={styles.products_modal_arrow_icons} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default memo(ManageProducts);
