import { createTheme, Switch as MaterialSwitch, ThemeProvider } from '@mui/material';
import React from 'react';
import scssVariables from '../../../../Config.module.scss';

export type AutoCompleteOption = { label: string };

interface IAutoCompleteInputProps {
    handleChange: (checked: boolean) => void;
    checked?: boolean;
    disabled?: boolean;
    testId?: string | undefined;
}

const theme = createTheme({
    palette: {
        primary: {
            main: scssVariables.agGridLinkLolor,
        },
    },
});

const Switch: React.FC<IAutoCompleteInputProps> = (props: IAutoCompleteInputProps) => (
    <ThemeProvider theme={theme}>
        <MaterialSwitch
            disabled={props.disabled}
            color="primary"
            checked={props.checked}
            onChange={(_, checked) => props.handleChange(checked)}
            data-testid={props.testId}
        />
    </ThemeProvider>
);

export default Switch;
