import { useEffect, useState } from 'react';
import TextInput from '../../../../../Components/Form/Inputs/TextInput';
import RegexValidators from '../../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../../Services/Language/useLanguageHook';
import { ValorizationRowModel } from '../../ValorizationCalculatorDefinitions';
import styles from '../ValorizationCalculatorActions.module.scss';

interface IValorizationBasisProps {
    modalValorizationRow: ValorizationRowModel;
    setModalValorizationRow: (modalValorizationRow: ValorizationRowModel) => void;
}

const ValorizationBasis: React.FC<IValorizationBasisProps> = (props: IValorizationBasisProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const { valueOverrides } = props.modalValorizationRow;

    const [basisTextValue, setBasisTextValue] = useState<string>();

    const basisOverrideVariable = props.modalValorizationRow.basis!.variableName!;
    const basisValue = (valueOverrides![basisOverrideVariable] ?? props.modalValorizationRow.basis?.value ?? 0)?.toString();

    useEffect(() => {
        setBasisTextValue(basisValue);
    }, []);

    return (
        <>
            <div className={styles.valorization_action_content_row}>
                <p className={styles.valorization_action_label_text}>{`${translations.words.basis} (+-)`}</p>
                <div className={styles.valorization_action_text_input_data_modal}>
                    <TextInput
                        value={basisTextValue}
                        skipHandleChangeWhenInvalid
                        handleTextChange={(value) => {
                            setBasisTextValue(value);

                            if (basisValue === value || Number.isNaN(+value)) {
                                return;
                            }

                            props.setModalValorizationRow({
                                ...props.modalValorizationRow,
                                valueOverrides: {
                                    ...props.modalValorizationRow.valueOverrides,
                                    [basisOverrideVariable]: +value,
                                },
                            });
                        }}
                        validation={RegexValidators.PositiveOrNegativeNumber}
                    />
                </div>
            </div>
            <div className={styles.valorization_action_input_separator} />
        </>
    );
};

export default ValorizationBasis;
