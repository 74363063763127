import { useEffect } from 'react';
import { demeterCalculatorsApi } from '../../../../../Apis/Apis';
import { DemeterFilterTimeSpan } from '../../../../../Generated/Raven-Demeter';
import useMultipleApis from '../../../../Apis/Hooks/useMultipleApisHook';
import Carousel from '../../../../Components/Carousel/Carousel';
import { ValorizationStreamRunRequest } from '../ValorizationCalculatorDefinitions';
import styles from './ValorizationCharts.module.scss';
import ValorizationCommodityPricesChart from './ValorizationCommodityPricesChart';
import ValorizationNetReturnsChart from './ValorizationNetReturnsChart';
import ValorizationStreamPricesChart from './ValorizationStreamPricesChart';

interface IValorizationCarouselProps {
    valorizationStreamRunRequests: ValorizationStreamRunRequest[];
}

const defaultFilterHistoryTimeSpan = DemeterFilterTimeSpan.FiveYears;

const ValorizationCarousel: React.FC<IValorizationCarouselProps> = (props: IValorizationCarouselProps) => {
    const filteredValorizationStreamRunRequests = props.valorizationStreamRunRequests?.filter((x) => x?.demeterCalculationEngineGuid && !x.isDeleted);

    // Api hooks.
    const [, refreshRunCalculationEngine, runCalculationEngineResponses] = useMultipleApis(
        () =>
            filteredValorizationStreamRunRequests.map((x) =>
                demeterCalculatorsApi.runTestCalculationEngine(x?.demeterCalculationEngineGuid!, {
                    demeterCalculationEngineGuid: x?.demeterCalculationEngineGuid!,
                    filterHistoryTimeSpan: defaultFilterHistoryTimeSpan,
                    showHistory: true,
                    dataOverrides: {},
                    valueOverrides: { ...x?.valueOverrides },
                    monthlyValueOverrides: [],
                }),
            ),
        { stopAutoExecute: true },
    );

    useEffect(() => {
        refreshRunCalculationEngine();
    }, [props.valorizationStreamRunRequests]);

    return (
        <div className={styles.valorization_chart_carousel}>
            <Carousel>
                <div className={styles.valorization_chart_carousel_chart}>
                    <ValorizationStreamPricesChart
                        runCalculationEngineResponses={runCalculationEngineResponses!}
                        valorizationStreamRunRequests={filteredValorizationStreamRunRequests}
                    />
                </div>
                <div className={styles.valorization_chart_carousel_chart}>
                    <ValorizationCommodityPricesChart
                        runCalculationEngineResponses={runCalculationEngineResponses!}
                        valorizationStreamRunRequests={filteredValorizationStreamRunRequests}
                    />
                </div>
                <div className={styles.valorization_chart_carousel_chart}>
                    <ValorizationNetReturnsChart
                        runCalculationEngineResponses={runCalculationEngineResponses!}
                        valorizationStreamRunRequests={filteredValorizationStreamRunRequests}
                    />
                </div>
            </Carousel>
        </div>
    );
};

export default ValorizationCarousel;
