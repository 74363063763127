import { Tooltip } from '@mui/material';
import React from 'react';
import infoSvg from '../../../Assets/Icons/info.svg';
import styles from './Inputs.module.scss';

interface ILabelWithTooltipProps {
    title: JSX.Element | string;
    tooltip: string | JSX.Element;
    position?: 'top' | 'middle';
    placement?:
        | 'top'
        | 'top-start'
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end';
}

const LabelWithTooltip: React.FC<ILabelWithTooltipProps> = (props: ILabelWithTooltipProps) => (
    <div className={styles.input_tooltip_wrapper}>
        {props.title}
        <Tooltip
            className={props.position === 'middle' ? styles.input_tooltip_middle : styles.input_tooltip}
            title={props.tooltip}
            placement={props.placement ?? 'top-start'}
        >
            <img src={infoSvg} alt="#" />
        </Tooltip>
    </div>
);

export default LabelWithTooltip;
