import { useEffect, useMemo, useState } from 'react';
import minusSvg from '../../../../../Assets/Icons/collapse.svg';
import plusSvg from '../../../../../Assets/Icons/expandGray.svg';
import TextInput from '../../../../../Components/Form/Inputs/TextInput';
import RegexValidators from '../../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../../Services/Language/useLanguageHook';
import { CostOfProductionCalculationValues, ValorizationRowModel } from '../../ValorizationCalculatorDefinitions';
import styles from '../ValorizationCalculatorActions.module.scss';

interface IValorizationCostOfProductionProps {
    modalValorizationRow: ValorizationRowModel;
    setModalValorizationRow: (modalValorizationRow: ValorizationRowModel) => void;
    costOfProductionCalculationValues: CostOfProductionCalculationValues;
}

const ValorizationCostOfProduction: React.FC<IValorizationCostOfProductionProps> = (props: IValorizationCostOfProductionProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const { valueOverrides } = props.modalValorizationRow;

    // Display hooks.
    const [costOfProductionTextValues, setCostOfProductionTextValues] = useState<{
        energy?: string;
        labor?: string;
        maintenance?: string;
        depreciation?: string;
        other?: string;
        total?: string;
    }>();
    const [costOfProductionLineExpanded, setCostOfProductionLineExpanded] = useState<boolean>();

    // Variable names.
    const costOfProductionOverrideVariable = props.modalValorizationRow.costOfProduction?.variableName!;
    const energyOverrideVariable = props.costOfProductionCalculationValues.energy?.variableName!;
    const laborOverrideVariable = props.costOfProductionCalculationValues.labor?.variableName!;
    const maintenanceOverrideVariable = props.costOfProductionCalculationValues.maintenance?.variableName!;
    const depreciationOverrideVariable = props.costOfProductionCalculationValues.depreciation?.variableName!;
    const otherOverrideVariable = props.costOfProductionCalculationValues.other?.variableName!;

    // Cost of production values.
    const energyValue = (valueOverrides![energyOverrideVariable] || props.costOfProductionCalculationValues.energy.value || '')?.toString();
    const laborValue = (valueOverrides![laborOverrideVariable] || props.costOfProductionCalculationValues.labor.value || '')?.toString();
    const maintenanceValue = (valueOverrides![maintenanceOverrideVariable] || props.costOfProductionCalculationValues.maintenance.value || '')?.toString();
    const depreciationValue = (valueOverrides![depreciationOverrideVariable] || props.costOfProductionCalculationValues.depreciation.value || '')?.toString();
    const otherValue = (valueOverrides![otherOverrideVariable] || props.costOfProductionCalculationValues.other?.value || '')?.toString();

    // TODO: If you update the total row, it will wipe out the individuals.
    const getSum = (...values: (number | undefined | null)[]): number | undefined => {
        if (values.every((x) => x === undefined || x === null)) {
            return undefined;
        }

        return values.reduce((currentSum: number, currentValue) => (currentValue || 0) + (currentSum || 0), 0);
    };

    const useTotalCostOfProductionRow = useMemo(
        () =>
            !costOfProductionTextValues?.energy &&
            !costOfProductionTextValues?.labor &&
            !costOfProductionTextValues?.maintenance &&
            !costOfProductionTextValues?.depreciation &&
            !costOfProductionTextValues?.other,
        [costOfProductionTextValues],
    );

    const sumOfRows = useMemo(
        () =>
            getSum(
                ...Object.values(props.costOfProductionCalculationValues).map((x) => {
                    if (valueOverrides![x?.variableName] ?? valueOverrides![x?.variableName] === 0) {
                        return valueOverrides![x?.variableName];
                    }

                    return x?.value;
                }),
            ),
        [props.costOfProductionCalculationValues, valueOverrides],
    );

    const costOfProductionValue = useMemo(() => {
        if (props.modalValorizationRow.valueOverrides![costOfProductionOverrideVariable] === 0) {
            return '';
        }

        if (!useTotalCostOfProductionRow) {
            return sumOfRows;
        }

        if (props.modalValorizationRow.valueOverrides![costOfProductionOverrideVariable]) {
            return props.modalValorizationRow.valueOverrides![costOfProductionOverrideVariable];
        }

        return props.modalValorizationRow.costOfProduction?.value;
    }, [useTotalCostOfProductionRow, costOfProductionLineExpanded, sumOfRows, props.modalValorizationRow]);

    useEffect(() => {
        if (useTotalCostOfProductionRow) {
            return;
        }

        setCostOfProductionLineExpanded(true);
    }, [useTotalCostOfProductionRow]);

    useEffect(() => {
        if (!costOfProductionValue) {
            return;
        }

        setCostOfProductionTextValues({
            energy: energyValue,
            labor: laborValue,
            maintenance: maintenanceValue,
            depreciation: depreciationValue,
            other: otherValue,
            total: costOfProductionLineExpanded && sumOfRows ? `${sumOfRows}` : `${costOfProductionValue}`,
        });
    }, [costOfProductionValue]);

    return (
        <>
            <div className={styles.valorization_action_content}>
                {costOfProductionLineExpanded && (
                    <>
                        <div className={styles.valorization_action_content_row}>
                            <p className={styles.valorization_action_label_text}>{translations.calculators.valorization.fields.energy}</p>
                            <div className={styles.valorization_action_text_input_data_modal}>
                                <TextInput
                                    skipHandleChangeWhenInvalid
                                    value={costOfProductionTextValues?.energy}
                                    handleTextChange={(value) => {
                                        setCostOfProductionTextValues({ ...costOfProductionTextValues, energy: value });

                                        if (energyValue === value || Number.isNaN(+value)) {
                                            return;
                                        }

                                        const modalRowOverrides = { ...props.modalValorizationRow.valueOverrides };
                                        delete modalRowOverrides[costOfProductionOverrideVariable];

                                        props.setModalValorizationRow({
                                            ...props.modalValorizationRow,
                                            valueOverrides: {
                                                ...modalRowOverrides,
                                                [energyOverrideVariable]: +value,
                                            },
                                        });
                                    }}
                                    validation={RegexValidators.PositiveOrNegativeNumber}
                                />
                            </div>
                        </div>
                        <div className={styles.valorization_action_input_separator} />
                        <div className={styles.valorization_action_content_row}>
                            <p className={styles.valorization_action_label_text}>{translations.calculators.valorization.fields.labor}</p>
                            <div className={styles.valorization_action_text_input_data_modal}>
                                <TextInput
                                    skipHandleChangeWhenInvalid
                                    value={costOfProductionTextValues?.labor}
                                    handleTextChange={(value) => {
                                        setCostOfProductionTextValues({ ...costOfProductionTextValues, labor: value });

                                        if (laborValue === value || Number.isNaN(+value)) {
                                            return;
                                        }

                                        const modalRowOverrides = { ...props.modalValorizationRow.valueOverrides };
                                        delete modalRowOverrides[costOfProductionOverrideVariable];

                                        props.setModalValorizationRow({
                                            ...props.modalValorizationRow,
                                            valueOverrides: {
                                                ...modalRowOverrides,
                                                [laborOverrideVariable]: +value,
                                            },
                                        });
                                    }}
                                    validation={RegexValidators.PositiveOrNegativeNumber}
                                />
                            </div>
                        </div>
                        <div className={styles.valorization_action_input_separator} />
                        <div className={styles.valorization_action_content_row}>
                            <p className={styles.valorization_action_label_text}>{translations.calculators.valorization.fields.maintenance}</p>

                            <div className={styles.valorization_action_text_input_data_modal}>
                                <TextInput
                                    skipHandleChangeWhenInvalid
                                    value={costOfProductionTextValues?.maintenance}
                                    handleTextChange={(value) => {
                                        setCostOfProductionTextValues({ ...costOfProductionTextValues, maintenance: value });

                                        if (maintenanceValue === value || Number.isNaN(+value)) {
                                            return;
                                        }
                                        const modalRowOverrides = { ...props.modalValorizationRow.valueOverrides };
                                        delete modalRowOverrides[costOfProductionOverrideVariable];

                                        props.setModalValorizationRow({
                                            ...props.modalValorizationRow,
                                            valueOverrides: {
                                                ...modalRowOverrides,
                                                [maintenanceOverrideVariable]: +value,
                                            },
                                        });
                                    }}
                                    validation={RegexValidators.PositiveOrNegativeNumber}
                                />
                            </div>
                        </div>
                        <div className={styles.valorization_action_input_separator} />
                        <div className={styles.valorization_action_content_row}>
                            <p className={styles.valorization_action_label_text}>{translations.calculators.valorization.fields.depreciation}</p>
                            <div className={styles.valorization_action_text_input_data_modal}>
                                <TextInput
                                    skipHandleChangeWhenInvalid
                                    value={costOfProductionTextValues?.depreciation}
                                    handleTextChange={(value) => {
                                        setCostOfProductionTextValues({ ...costOfProductionTextValues, depreciation: value });

                                        if (depreciationValue === value || Number.isNaN(+value)) {
                                            return;
                                        }
                                        const modalRowOverrides = { ...props.modalValorizationRow.valueOverrides };
                                        delete modalRowOverrides[costOfProductionOverrideVariable];

                                        props.setModalValorizationRow({
                                            ...props.modalValorizationRow,
                                            valueOverrides: {
                                                ...modalRowOverrides,
                                                [depreciationOverrideVariable]: +value,
                                            },
                                        });
                                    }}
                                    validation={RegexValidators.PositiveOrNegativeNumber}
                                />
                            </div>
                        </div>
                        <div className={styles.valorization_action_input_separator} />
                        <div className={styles.valorization_action_content_row}>
                            <p className={styles.valorization_action_label_text}>{translations.words.other}</p>
                            <div className={styles.valorization_action_text_input_data_modal}>
                                <TextInput
                                    skipHandleChangeWhenInvalid
                                    value={costOfProductionTextValues?.other}
                                    handleTextChange={(value) => {
                                        setCostOfProductionTextValues({ ...costOfProductionTextValues, other: value });

                                        if (otherValue === value || Number.isNaN(+value)) {
                                            return;
                                        }
                                        const modalRowOverrides = { ...props.modalValorizationRow.valueOverrides };
                                        delete modalRowOverrides[costOfProductionOverrideVariable];

                                        props.setModalValorizationRow({
                                            ...props.modalValorizationRow,
                                            valueOverrides: {
                                                ...modalRowOverrides,
                                                [otherOverrideVariable]: +value,
                                            },
                                        });
                                    }}
                                    validation={RegexValidators.PositiveOrNegativeNumber}
                                />
                            </div>
                        </div>
                        <div className={styles.valorization_action_input_separator} />
                    </>
                )}
                <div className={styles.valorization_action_content_row}>
                    <div className={styles.valorization_action_expanding_button_with_text}>
                        <p className={styles.valorization_action_label_text}>{translations.words.total}</p>
                        <button onClick={() => setCostOfProductionLineExpanded(!costOfProductionLineExpanded)} type="button">
                            <img src={costOfProductionLineExpanded ? minusSvg : plusSvg} alt="#" />
                        </button>
                    </div>
                    <div className={styles.valorization_action_text_input_data_modal}>
                        <TextInput
                            disabled={costOfProductionLineExpanded}
                            skipHandleChangeWhenInvalid
                            value={costOfProductionTextValues?.total}
                            handleTextChange={(value) => {
                                setCostOfProductionTextValues({ ...costOfProductionTextValues, total: value });

                                if (costOfProductionTextValues?.total === value || Number.isNaN(+value) || costOfProductionLineExpanded) {
                                    return;
                                }

                                const modalRowOverrides = { ...props.modalValorizationRow.valueOverrides };
                                delete modalRowOverrides[costOfProductionOverrideVariable];
                                delete modalRowOverrides[energyOverrideVariable];
                                delete modalRowOverrides[laborOverrideVariable];
                                delete modalRowOverrides[maintenanceOverrideVariable];
                                delete modalRowOverrides[depreciationOverrideVariable];
                                delete modalRowOverrides[otherOverrideVariable];

                                props.setModalValorizationRow({
                                    ...props.modalValorizationRow,
                                    valueOverrides: {
                                        ...modalRowOverrides,
                                        [costOfProductionOverrideVariable]: +value,
                                    },
                                });
                            }}
                            validation={RegexValidators.PositiveOrNegativeNumber}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.valorization_action_input_separator} />
        </>
    );
};

export default ValorizationCostOfProduction;
