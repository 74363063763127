import { useEffect, useState } from 'react';
import TextInput from '../../../../../Components/Form/Inputs/TextInput';
import RegexValidators from '../../../../../Core/Validation/RegexValidators';
import formattingService from '../../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../../Services/Language/useLanguageHook';
import { ValorizationRowModel } from '../../ValorizationCalculatorDefinitions';
import styles from '../ValorizationCalculatorActions.module.scss';

interface IValorizationYieldProps {
    modalValorizationRow: ValorizationRowModel;
    setModalValorizationRow: (modalValorizationRow: ValorizationRowModel) => void;
}

const defaultMaximumDecimalPlaces = 6;

const ValorizationYield: React.FC<IValorizationYieldProps> = (props: IValorizationYieldProps) => {
    const [translations] = useLanguage();
    const { valueOverrides } = props.modalValorizationRow;
    const yieldOverrideVariable = props.modalValorizationRow.yield!.variableName!!;

    const [yieldTextValue, setYieldTextValue] = useState<string>();

    const yieldInputValue = formattingService.toNumberStringWithTrailingZeros(
        valueOverrides![yieldOverrideVariable] ?? props.modalValorizationRow.yield?.value ?? 0,
        defaultMaximumDecimalPlaces,
        defaultMaximumDecimalPlaces,
    );

    useEffect(() => {
        setYieldTextValue(yieldInputValue);
    }, []);

    return (
        <>
            <div className={styles.valorization_action_content_row}>
                <p className={styles.valorization_action_label_text}>{translations.words.yield}</p>
                <div className={styles.valorization_action_text_input_data_modal}>
                    <TextInput
                        skipHandleChangeWhenInvalid
                        value={yieldTextValue}
                        handleTextChange={(value) => {
                            setYieldTextValue(value);

                            if (yieldInputValue === value || Number.isNaN(+value)) {
                                return;
                            }

                            props.setModalValorizationRow({
                                ...props.modalValorizationRow,
                                valueOverrides: {
                                    ...props.modalValorizationRow.valueOverrides,
                                    [yieldOverrideVariable]: +value,
                                },
                            });
                        }}
                        validation={RegexValidators.PositiveOrNegativeNumber}
                    />
                </div>
            </div>
            <div className={styles.valorization_action_input_separator} />
        </>
    );
};

export default ValorizationYield;
