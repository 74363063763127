import { useEffect, useMemo, useState } from 'react';
import { RunCalculationEngineResponse } from '../../../../../Generated/Raven-Demeter';
import BarChartRaw from '../../../../Components/Charts/Bar/BarChartRaw';
import { IChartBarDataSeries } from '../../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../../Components/Charts/ChartWrapper/ChartWrapper';
import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import ComponentSubHeader from '../../../../Components/Headers/ComponentSubHeader';
import useCurrency from '../../../../Core/Hooks/useCurrencyHook';
import useUnitOfMeasure from '../../../../Core/Hooks/useUnitOfMeasureHook';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { ValorizationStreamRunRequest } from '../ValorizationCalculatorDefinitions';
import styles from './ValorizationCharts.module.scss';

export interface IValorizationNetReturnsChart {
    runCalculationEngineResponses: RunCalculationEngineResponse[];
    valorizationStreamRunRequests: ValorizationStreamRunRequest[];
}

const ValorizationNetReturnsChart: React.FC<IValorizationNetReturnsChart> = (props: IValorizationNetReturnsChart) => {
    const [translations] = useLanguage();
    const [currency] = useCurrency();
    const [unitOfMeasure] = useUnitOfMeasure('General');
    // Initialize with first stream.
    const [leftStreamOrder, setLeftStreamOrder] = useState<number>(1);
    // Initialize with next stream if availible.
    const [rightStreamOrder, setRightStreamOrder] = useState<number>(props.valorizationStreamRunRequests.length >= 2 ? 2 : 1);

    useEffect(() => {
        if (props.valorizationStreamRunRequests.length < leftStreamOrder) {
            setLeftStreamOrder(props.valorizationStreamRunRequests.length);
        }

        if (props.valorizationStreamRunRequests.length < rightStreamOrder) {
            setRightStreamOrder(props.valorizationStreamRunRequests.length);
        }
    }, [props.valorizationStreamRunRequests]);

    const barSeries = useMemo<IChartBarDataSeries[]>(() => {
        if (
            !props.runCalculationEngineResponses ||
            !props.runCalculationEngineResponses.length ||
            !props.runCalculationEngineResponses[0].results ||
            !props.runCalculationEngineResponses[0].results.length
        ) {
            return [];
        }

        const leftSideData = props.runCalculationEngineResponses[leftStreamOrder - 1].results?.map((x) => ({
            value: x.lines.find((y) => y.displayName === 'Milk Price')?.value,
            asOfDate: x.asOfDate,
            isActualValue: true,
        }));

        const rightSideData = props.runCalculationEngineResponses[rightStreamOrder - 1].results?.map((x) => ({
            value: x.lines.find((y) => y.displayName === 'Milk Price')?.value,
            asOfDate: x.asOfDate,
            isActualValue: true,
        }));

        if (!leftSideData?.length) {
            return [];
        }

        return [
            {
                label: translations.calculators.valorization.text.netReturns,
                forecastLabel: `${translations.calculators.valorization.text.netReturns} ${translations.words.forecast}`,
                data: leftSideData
                    .filter((x) => rightSideData?.some((y) => y.asOfDate === x.asOfDate))
                    .map((x) => {
                        const matchingValue = rightSideData?.find((y) => y.asOfDate === x.asOfDate);
                        return {
                            value: (x.value || 0) - (matchingValue?.value ?? 0),
                            asOfDate: new Date(x.asOfDate),
                            isActualValue: new Date(x?.asOfDate!).getTime() < new Date().getTime(),
                        };
                    }),
            },
        ];
    }, [props.valorizationStreamRunRequests, props.runCalculationEngineResponses, leftStreamOrder, rightStreamOrder]) as IChartBarDataSeries[];

    const titleString = useMemo(() => `${translations.calculators.valorization.text.netReturns}`, [translations]);

    const dataSourceTag = useMemo(() => translations.dataSource.StoneXCalculations, [translations]);

    const streamOptions = useMemo(() => {
        if (!props.valorizationStreamRunRequests.length) {
            return [];
        }

        return props.valorizationStreamRunRequests.map((x) => ({
            label: x.name || '',
            value: x.order,
        }));
    }, [props.runCalculationEngineResponses]) as { label: string; value: number }[];

    const chartSubHeader = useMemo(() => {
        if (!streamOptions.length) {
            return <> </>;
        }

        return (
            <div className={styles.valorization_net_return_select}>
                <div className={styles.valorization_net_return_select_dropdown}>
                    <Dropdown
                        value={streamOptions.find((x) => x.value === leftStreamOrder)?.value ?? streamOptions[1].value}
                        options={streamOptions}
                        handleOptionChange={(value) => {
                            setLeftStreamOrder(value);
                            if (value === rightStreamOrder && props.valorizationStreamRunRequests.length > 1) {
                                setRightStreamOrder(
                                    props.valorizationStreamRunRequests.length === rightStreamOrder ? rightStreamOrder - 1 : rightStreamOrder + 1,
                                );
                            }
                        }}
                    />
                </div>
                <ComponentSubHeader title={translations.words.over} />
                <div className={styles.valorization_net_return_select_dropdown}>
                    <Dropdown
                        value={streamOptions.find((x) => x.value === rightStreamOrder)?.value ?? streamOptions[0].value}
                        options={streamOptions}
                        handleOptionChange={(value) => {
                            if (value !== leftStreamOrder || props.valorizationStreamRunRequests.length === 1) {
                                setRightStreamOrder(value);
                            } else {
                                setRightStreamOrder(props.valorizationStreamRunRequests.length === leftStreamOrder ? value - 1 : value + 1);
                            }
                        }}
                    />
                </div>
            </div>
        );
    }, [streamOptions, leftStreamOrder, rightStreamOrder, props.valorizationStreamRunRequests, props.runCalculationEngineResponses]);

    const loading = !props.runCalculationEngineResponses;

    return (
        <ChartWrapper name="ValorizationNetReturnsChart" title={titleString} header={chartSubHeader} dataSourceTag={dataSourceTag} isLoading={loading}>
            <BarChartRaw barSeries={barSeries} currency={currency} unitOfMeasure={unitOfMeasure} format="currency" />
        </ChartWrapper>
    );
};

export default ValorizationNetReturnsChart;
