import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useMemo } from 'react';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import useLanguage from '../../../Services/Language/useLanguageHook';
import Dropdown from '../../Form/Inputs/Dropdown';
import { ApiListResponse } from './ApiListResponse';
import styles from './TableWrapper.module.scss';

interface IPaginationProps {
    apiListResponse: ApiListResponse;
    handlePagination?: (updatedPagination: ApiListResponse) => void;
    handleTakeCountUpdate?: (updatedTakeCount: ApiListResponse) => void;
}

type PaginationDirection = 'previous' | 'next';

const Pagination = (props: IPaginationProps) => {
    const [translations] = useLanguage();
    const { totalCount } = props.apiListResponse;
    const start = useMemo(() => (props.apiListResponse.skip ?? 0) + 1, [props.apiListResponse]);
    const take = useMemo(() => props.apiListResponse.take ?? 20, [props.apiListResponse]);
    const dropdownOptions = useMemo(() => {
        if (!totalCount) {
            return [];
        }

        const options = [
            {
                label: '20',
                value: 20,
            },
        ];

        if (totalCount > 20) {
            options.push({
                label: '50',
                value: 50,
            });
        }

        if (totalCount > 50) {
            options.push({
                label: '100',
                value: 100,
            });
        }

        return options;
    }, [totalCount]);

    const handleOptionChange = (value: number) => {
        if (!props.handleTakeCountUpdate) {
            return null;
        }

        const newApiListResponse: ApiListResponse = {
            ...props.apiListResponse,
            take: value,
        };
        props.handleTakeCountUpdate(newApiListResponse);
        return value;
    };

    const getPageRange = (): string => {
        let end = start + (take ?? applicationConstants.ItemsPerPage) - 1;
        if (end < 0) {
            end = 0;
        } else if (totalCount && end > totalCount) {
            end = totalCount;
        }

        return `${start} - ${end}`;
    };

    const handlePaginationClick = (direction: PaginationDirection): void => {
        const newSkip = direction === 'next' ? (props.apiListResponse.skip ?? 0) + take : Math.max(0, (props.apiListResponse.skip ?? 0) - take);

        if (newSkip > (totalCount ?? 0)) {
            return;
        }

        const newApiListResponse: ApiListResponse = {
            ...props.apiListResponse,
            skip: newSkip,
        };

        if (props.handlePagination) {
            props.handlePagination(newApiListResponse);
        }
    };

    return (
        <div className={styles.table_wrapper}>
            <div className={styles.table_wrapper_table_container}>
                <div className={styles.table_wrapper_pagination_control_box}>
                    {totalCount && totalCount > applicationConstants.ItemsPerPage && (
                        <div className={styles.table_wrapper_select_items_count}>
                            <div className={styles.table_wrapper_select_items_text}>{translations.userData.management.table.itemsPerPage}:</div>
                            <div>
                                <Dropdown
                                    className={styles.table_wrapper_dropdown}
                                    customStyles={{
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                    }}
                                    value={take}
                                    options={dropdownOptions}
                                    handleOptionChange={handleOptionChange}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles.table_wrapper_pagination_control_box_text}>
                        <div className={styles.table_wrapper_pages_count}>
                            {getPageRange()} {translations.words.of} {totalCount}
                        </div>
                        <button className={styles.table_wrapper_transparent_button} type="button" onClick={() => handlePaginationClick('previous')}>
                            <KeyboardArrowLeftIcon className={styles.table_wrapper_pagination_control} />
                        </button>
                        <button className={styles.table_wrapper_transparent_button} type="button" onClick={() => handlePaginationClick('next')}>
                            <KeyboardArrowRightIcon className={styles.table_wrapper_pagination_control} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
