import { Options, YAxisOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Currency, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../Services/Logging/LoggingService';
import {
    areaRangeChartType,
    chartColors,
    ChartContext,
    ChartOptionsDefinitions,
    defalutLineWidth,
    defaultChartAxisTickLabelStyle,
    defaultChartAxisTitleStyle,
    defaultChartOptions,
    featuredLineWidth,
    IChartAreaRangeDataSeries,
    IChartDataSeries,
    IChartProps,
    lineChartType,
} from '../ChartDefinitions';
import chartService from '../ChartService';

export interface IMultiAreaLineChartRawProps extends IChartProps {
    lineSeries: IChartDataSeries[];
    areaSeries: IChartAreaRangeDataSeries[];
    // This (focusOnLine) gives us the option to use the line for getting the upper and lower bounds of the chart, thus
    // ignoring all area series when determining the display area.
    focusOnLine?: boolean;
    startDate?: Date;
    endDate?: Date;
    unitOfMeasure?: UnitOfMeasure;
    currency?: Currency;
}

const areaTickAmount = 10;

const MultiAreaLineChartRaw: React.FC<IMultiAreaLineChartRawProps> = (props: IMultiAreaLineChartRawProps) => {
    // Chart hooks.
    const multiBarWithLinesChartDefaultOptions = useMemo<Options>(
        () => ({
            ...defaultChartOptions,
            yAxis: [
                {
                    title: {
                        text: props.currency,
                        style: defaultChartAxisTitleStyle,
                    },
                    labels: {
                        style: defaultChartAxisTickLabelStyle,
                    },
                    tickAmount: areaTickAmount,
                },
            ],

            series: [],
        }),
        [],
    );

    const [highchartOptions, setHighchartOptions] = useState<Options>(multiBarWithLinesChartDefaultOptions);

    const getDataSeriesDefinitions = useCallback(() => {
        const areasDataSeries = props.areaSeries.flatMap((areaSeries) => {
            const currentColor = chartColors.priceDecileAlphaColorsRuleSetTwo.find((x) => x.decileRank === areaSeries.decileRank!)!.color;

            return {
                ...chartService.getDataSeriesBase(areaSeries, areaRangeChartType, currentColor),
                showInLegend: false,
                tooltip: {
                    customTooltipPerSeries() {
                        return chartService.getAreaRangeTooltipText(this as unknown as ChartContext);
                    },
                },
                events: {
                    legendItemClick() {
                        loggingService.trackEventWithAnalytics(
                            EventActionsEnum.ButtonClick,
                            EventCategoriesEnum.LegendItemClicked,
                            areaSeries.label,
                            EventDataTargetsEnum.MultiAreaLineChart,
                        );
                    },
                },
            };
        });

        const lineDataSeries = props.lineSeries.flatMap((lineSeries, index) => {
            const currentColor = chartColors.valueMatrixLineChartColors[index];
            const primaryLine = index === 0 || lineSeries.isPrimaryLine;
            return {
                ...chartService.getDataSeriesBase(lineSeries, lineChartType, currentColor),
                tooltip: {
                    customTooltipPerSeries() {
                        return chartService.getTooltipText(this as unknown as ChartContext);
                    },
                },
                events: {
                    legendItemClick() {
                        loggingService.trackEventWithAnalytics(
                            EventActionsEnum.ButtonClick,
                            EventCategoriesEnum.LegendItemClicked,
                            lineSeries.label,
                            EventDataTargetsEnum.MultiAreaLineChart,
                        );
                    },
                },
                lineWidth: primaryLine ? featuredLineWidth : defalutLineWidth,
            };
        });

        return [...areasDataSeries, ...lineDataSeries];
    }, [props.lineSeries, props.areaSeries]);

    const getMinimumValue = (initialArray: number[]) => {
        let arrayLength = initialArray.length;
        let minimumValue = Infinity;

        while (arrayLength) {
            if (Number(initialArray[arrayLength]) < minimumValue) {
                minimumValue = Number(initialArray[arrayLength]);
            }

            arrayLength -= 1;
        }

        return minimumValue;
    };

    const getMaximumValue = (initialArray: number[]) => {
        let arrayLength = initialArray.length;
        let maximumValue = -Infinity;

        while (arrayLength) {
            if (Number(initialArray[arrayLength]) > maximumValue) {
                maximumValue = Number(initialArray[arrayLength]);
            }

            arrayLength -= 1;
        }

        return maximumValue;
    };

    // Main useEffect to update chart when props or data changes.
    useEffect(() => {
        const dataSeries = getDataSeriesDefinitions();
        const allSeries = [
            ...props.areaSeries.map((x) => ({ ...x, data: x.data.filter((y) => y.asOfDate >= props.startDate! && y.asOfDate <= props.endDate!) })),
            ...props.lineSeries,
        ];

        const downloadData = chartService.getDownloadDataForAreaRange(allSeries, dataSeries as ChartOptionsDefinitions[], undefined, true);

        const newOptions = {
            ...multiBarWithLinesChartDefaultOptions,
            ...{
                series: dataSeries,
            },
            ...{ yAxis: [...(multiBarWithLinesChartDefaultOptions.yAxis as YAxisOptions[])!] },
            tooltip: {
                formatter() {
                    return (this as unknown as ChartContext).series.tooltipOptions.customTooltipPerSeries.call(this);
                },
            },
            downloadData,
        };

        // Because of the size of some datasets in use for this chart type, we need custom functions to calculate the
        // minimum and maximum value. Passing too many values into a Math.min or Math.max results in a stack overflow.
        const lineMinimumValue = getMinimumValue([...props.lineSeries.map((x) => x.data.map((y) => y.value)).flat()]);
        const minimumValue = getMinimumValue([
            ...props.areaSeries.map((x) => x.data.filter((y) => y.minimumValue).map((y) => y.minimumValue)).flat(),
            lineMinimumValue,
        ]);

        const lineMaximumValue = getMaximumValue([...props.lineSeries.map((x) => x.data.map((y) => y.value)).flat()]);
        const maximumValue = getMaximumValue([
            ...props.areaSeries.map((x) => x.data.filter((y) => y.maximumValue).map((y) => y.maximumValue)).flat(),
            lineMaximumValue,
        ]);

        (newOptions.yAxis as YAxisOptions[])[0].min = props.focusOnLine ? lineMinimumValue : minimumValue;
        (newOptions.yAxis as YAxisOptions[])[0].max = props.focusOnLine ? lineMaximumValue : maximumValue;
        (newOptions.yAxis as YAxisOptions[])[0].title!.text = chartService.getCurrencyAndUnitOfMeasureText(props.unitOfMeasure, props.currency);

        setHighchartOptions(newOptions as Options);
    }, [props.lineSeries, props.areaSeries]);

    return <HighchartsReact ref={props.chartReference} highcharts={HighStock} options={highchartOptions} containerProps={{ style: { height: '100%' } }} />;
};

export default MultiAreaLineChartRaw;
