import React from 'react';
import { demeterWorkerSyncStatusesApi } from '../../../../Apis/Apis';
import scssVariables from '../../../../Config.module.scss';
import { WorkerLogType } from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import formattingService from '../../../Services/Formatting/FormattingService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './WorkerLogsPage.module.scss';

const WorkerLogsPage: React.FC = () => {
    const [translations] = useLanguage();

    const [loading, , apiResponse] = useApi(() => demeterWorkerSyncStatusesApi.listDemeterWorkerSyncStatuses());

    const displayStatus = (status: WorkerLogType | undefined) => {
        const style = { backgroundColor: '', color: scssVariables.mainTextColor };

        switch (status) {
            case WorkerLogType.Complete:
                style.backgroundColor = scssVariables.activeBlockColor;
                break;
            case WorkerLogType.Data:
                style.backgroundColor = scssVariables.stonexMediumGray;
                style.color = scssVariables.plainWhite;
                break;
            case WorkerLogType.Error:
                style.backgroundColor = scssVariables.expiredBlockColor;
                break;
            case WorkerLogType.Log:
                style.backgroundColor = scssVariables.pendingBlockColor;
                break;
            case WorkerLogType.Setup:
                style.backgroundColor = scssVariables.pendingBlockColor;
                break;
            case WorkerLogType.Start:
                style.backgroundColor = scssVariables.stonexPrimaryBlue;
                style.color = scssVariables.plainWhite;
                break;
            default:
                style.backgroundColor = scssVariables.deactivatedBlockColor;
                break;
        }

        return (
            <div className={styles.worker_logs_status_field} style={style}>
                {status}
            </div>
        );
    };

    return loading ? (
        <PageLoadingSpinner />
    ) : (
        <div>
            <div data-testid="WorkerLogsTable" className={styles.worker_logs_table}>
                <div className={styles.worker_logs_table_row}>
                    <div className={styles.worker_logs_table_header_cell}>{translations.workers.fields.name}</div>
                    <div className={styles.worker_logs_table_header_cell}>{translations.workers.fields.lastRun}</div>
                    <div className={styles.worker_logs_table_header_cell}>{translations.workers.fields.lastStatus}</div>
                    <div className={styles.worker_logs_table_header_cell}>{translations.workers.fields.lastSuccessDate}</div>
                    <div className={styles.worker_logs_table_header_cell}>{translations.workers.fields.lastFailureDate}</div>
                    <div className={styles.worker_logs_table_header_cell}>{translations.workers.fields.lastFailureMessage}</div>
                </div>
                {apiResponse?.rows &&
                    apiResponse.rows.map((workerLog) => (
                        <div key={`row_${workerLog.name}`} className={styles.worker_logs_table_row}>
                            <div className={styles.worker_logs_table_cell_name}>{workerLog.name}</div>
                            <div className={styles.worker_logs_table_cell_date}>{formattingService.toTimestampFromUtc(workerLog.lastRunAt)}</div>
                            <div className={styles.worker_logs_table_cell_status}>{displayStatus(workerLog.lastStatus)}</div>
                            <div className={styles.worker_logs_table_cell_date}>{formattingService.toTimestampFromUtc(workerLog.lastSuccessfulRunAt)}</div>
                            <div className={styles.worker_logs_table_cell_date}>{formattingService.toTimestampFromUtc(workerLog.lastFailedRunAt)}</div>
                            <div className={styles.worker_logs_table_cell_message}>{workerLog.lastErrorMessage}</div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default WorkerLogsPage;
