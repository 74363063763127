import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../../../Services/Logging/LoggingService';
import { SelectInputOption } from '../../Inputs/SelectInput';
import styles from './JoinedSelectionButtons.module.scss';

export interface IJoinedSelectionButtonsProps<T> {
    name: EventDataTargetsEnum;
    options: SelectInputOption<T>[];
    handleSelectionChange: (dataFrequency: T) => void;
    selection: T;
    testId?: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const JoinedSelectionButtons = <T,>(props: IJoinedSelectionButtonsProps<T>) => {
    const updateButtonSelectionWithAnalytics = (currentSelection: T) => {
        loggingService.trackEventWithAnalytics(EventActionsEnum.ButtonClick, EventCategoriesEnum.JoinedButtonsClicked, `${currentSelection}`, props.name);
        props.handleSelectionChange(currentSelection);
    };

    return (
        <div className={styles.joined_button_selection_buttons}>
            {props.options.map((element) => (
                <button
                    key={element.label}
                    onClick={() => updateButtonSelectionWithAnalytics(element.value)}
                    className={element.value === props.selection ? styles.joined_button_selected : styles.joined_button_not_selected}
                    type="button"
                    data-testid={`${props.testId}${element.label}`}
                >
                    {element.label}
                </button>
            ))}
        </div>
    );
};

export default JoinedSelectionButtons;
