import React, { useEffect, useMemo, useState } from 'react';
import { DemeterMarket, GetMarketIndicatorResponse, ListMarketIndicatorsResponse, MarketIndicatorOutlook } from '../../../Generated/Raven-Demeter';
import useLanguage from '../../Services/Language/useLanguageHook';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../Services/Logging/DataLayerDefinitions';
import loggingService from '../../Services/Logging/LoggingService';
import useSearchParameters from '../Navigation/Hooks/useSearchParametersHook';
import styles from './MenuFileSelectors.module.scss';

interface IMenuByMarketIndicatorNavigationProps {
    listMarketIndicatorsResponse?: ListMarketIndicatorsResponse;
    getMarketIndicatorByRegionCommodityResponse?: GetMarketIndicatorResponse;
    isHedgeMonitorPro?: boolean;
}

const MenuByMarketIndicatorNavigation: React.FC<IMenuByMarketIndicatorNavigationProps> = (props: IMenuByMarketIndicatorNavigationProps) => {
    // Application hooks and constants.
    const [translations, translate] = useLanguage();
    const [selectedMarket, setSelectedMarket] = useState<DemeterMarket>();
    const [searchParameters, setSearchParameters] = useSearchParameters();

    const listMarketIndicatorsPublishedRows = useMemo(() => {
        const newlistMarketIndicatorRows = props.listMarketIndicatorsResponse?.rows?.filter((x) => x.isPublished);

        if (props.isHedgeMonitorPro) {
            return newlistMarketIndicatorRows?.filter((x) => {
                const isAvailableInHedgeMonitorPro = x.outlook !== MarketIndicatorOutlook.Unavailable && x.hedgeMonitorProOutlook && x.precipiceOutlook;
                return isAvailableInHedgeMonitorPro;
            });
        }

        return newlistMarketIndicatorRows;
    }, [props.listMarketIndicatorsResponse]);

    // On first navigation to page, set the selected market (so it's not selecting 'All').
    useEffect(() => {
        if (selectedMarket || !searchParameters?.marketIndicatorGuid || !listMarketIndicatorsPublishedRows) {
            return;
        }

        const newSelectedMarket = listMarketIndicatorsPublishedRows.find((x) => x.marketIndicatorGuid === searchParameters.marketIndicatorGuid)?.market;
        setSelectedMarket(newSelectedMarket);
    }, [searchParameters?.marketIndicatorGuid, listMarketIndicatorsPublishedRows]);

    useEffect(() => {
        if (!listMarketIndicatorsPublishedRows || searchParameters?.marketIndicatorGuid || listMarketIndicatorsPublishedRows.length === 0) {
            return;
        }

        const firstOfMarketOrFirstOfAll = listMarketIndicatorsPublishedRows.find((x) => x.market === selectedMarket) ?? listMarketIndicatorsPublishedRows[0];

        setSearchParameters({ marketIndicatorGuid: firstOfMarketOrFirstOfAll?.marketIndicatorGuid! });
    }, [listMarketIndicatorsPublishedRows]);

    const marketIndicatorMarketOptions = useMemo(() => {
        if (!listMarketIndicatorsPublishedRows) {
            return [];
        }

        const options: { label: string; value: DemeterMarket }[] = [];

        listMarketIndicatorsPublishedRows?.forEach((x) => {
            if (options.find((y) => y.value === x.market)) {
                return;
            }

            options.push({ label: translations.market[x.market!], value: x.market! });
        });

        options.push({ label: translations.market[DemeterMarket.All], value: DemeterMarket.All });

        return options;
    }, [listMarketIndicatorsPublishedRows, props.getMarketIndicatorByRegionCommodityResponse, translations]);

    return (
        <>
            <div className={styles.menu_by_market_tabs}>
                {marketIndicatorMarketOptions.map((x) => (
                    <button
                        className={selectedMarket === x.value ? styles.menu_by_market_tab_selected : styles.menu_by_market_tab}
                        type="button"
                        onClick={() => {
                            loggingService.trackEventWithAnalytics(
                                EventActionsEnum.MenuSelection,
                                EventCategoriesEnum.MarketChange,
                                x.value,
                                props.isHedgeMonitorPro ? EventDataTargetsEnum.HedgeMonitorPro : EventDataTargetsEnum.CommodityIndicator,
                            );
                            setSelectedMarket(x.value);
                            setSearchParameters({
                                marketIndicatorGuid: listMarketIndicatorsPublishedRows?.filter((y) => x.value === y.market)[0].marketIndicatorGuid!,
                            });
                        }}
                        data-testid={`MarketIndicatorMarket${x.value}`}
                    >
                        {x.label}
                    </button>
                ))}
            </div>
            <div className={styles.menu_by_market} data-testid="MenuByMarketIndicatorNavigation">
                {listMarketIndicatorsPublishedRows
                    ?.filter((x) => selectedMarket === x.market || selectedMarket === DemeterMarket.All)
                    .map((marketIndicator) => (
                        <button
                            type="button"
                            onClick={() => {
                                loggingService.trackEventWithAnalytics(
                                    EventActionsEnum.MenuSelection,
                                    EventCategoriesEnum.CommodityChange,
                                    marketIndicator.commodity,
                                    props.isHedgeMonitorPro ? EventDataTargetsEnum.HedgeMonitorPro : EventDataTargetsEnum.CommodityIndicator,
                                );
                                setSearchParameters({ marketIndicatorGuid: marketIndicator.marketIndicatorGuid });
                            }}
                            className={
                                marketIndicator.marketIndicatorGuid === searchParameters.marketIndicatorGuid
                                    ? styles.menu_by_market_button_selected
                                    : styles.menu_by_market_button
                            }
                            data-testid={`MenuByMarketIndicatorNavigation${marketIndicator.displayName}`}
                        >
                            {translate(marketIndicator.displayName ?? '')}
                        </button>
                    ))}
            </div>
        </>
    );
};

export default MenuByMarketIndicatorNavigation;
