import { useState } from 'react';
import { GetCalculationEngineResponse } from '../../../../../../Generated/Raven-Demeter';
import editStreamIcon from '../../../../../Assets/Icons/editStreamIcon.svg';
import Dropdown from '../../../../../Components/Form/Inputs/Dropdown';
import TextInput from '../../../../../Components/Form/Inputs/TextInput';
import ActionModal from '../../../../../Components/Modals/ActionModal/ActionModal';
import RegexValidators from '../../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../../Services/Language/useLanguageHook';
import { UpsertStreamRequest, ValorizationStreamRunRequest, ValorizationUpsertRequest } from '../../ValorizationCalculatorDefinitions';
import styles from '../ValorizationCalculatorActions.module.scss';

interface IValorizationValorizationEditStreamActionProps {
    getCalculationEngineResponses: GetCalculationEngineResponse[];
    upsertStreamOptions: { label: string; value: string }[];
    upsertStreamRequest: UpsertStreamRequest;
    setUpsertStreamRequest: (upsertStreamRequest: UpsertStreamRequest) => void;
    valorizationStreamRunRequest: ValorizationStreamRunRequest;
    valorizationUpsertRequest: ValorizationUpsertRequest;
    handleUpdateValorizationUpsertRequest: (valorizationUpsertRequest: ValorizationUpsertRequest, isBaseRequest?: boolean) => void;
    handleUpdateValorizationStreamRunRequests: (newValorizationRunRequest: ValorizationStreamRunRequest, isBaseRequest?: boolean) => void;
}

const ValorizationEditStreamAction: React.FC<IValorizationValorizationEditStreamActionProps> = (props: IValorizationValorizationEditStreamActionProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Modal display hook.
    const [showUpdateSteamsModal, setShowUpdateSteamsModal] = useState(false);

    return (
        <>
            <button
                type="button"
                onClick={() => {
                    props.setUpsertStreamRequest({
                        name: props.valorizationStreamRunRequest.name,
                        demeterCalculationEngineGuid: props.valorizationStreamRunRequest.demeterCalculationEngineGuid,
                        overrideName: undefined,
                        overrideDemeterCalculationEngineGuid: undefined,
                    });
                    setShowUpdateSteamsModal(true);
                }}
            >
                <img src={editStreamIcon} alt="#" /> {translations.calculators.valorization.text.editStream}
            </button>
            <ActionModal
                header={translations.calculators.valorization.text.editStream}
                showModal={showUpdateSteamsModal}
                handleCancel={() => setShowUpdateSteamsModal(false)}
                actionButtonName={translations.actions.apply}
                actionButtonDisabled={!RegexValidators.AlphaNumericMinimumLength2.test(props.upsertStreamRequest?.overrideName ?? '')}
                handleAction={() => {
                    const currentStreamModel = props.getCalculationEngineResponses?.find(
                        (x) => x.calculationEngine?.demeterCalculationEngineGuid === props.upsertStreamRequest?.overrideDemeterCalculationEngineGuid,
                    );

                    let initialValorizationStreamRunRequests = {
                        ...props.valorizationStreamRunRequest,
                        overrideName: props.upsertStreamRequest?.overrideName,
                    };

                    let initialValorizationUpsertRequest = {
                        ...props.valorizationUpsertRequest,
                        overrideName: props.upsertStreamRequest?.overrideName,
                    };

                    // When we change streams, we reset.
                    if (props.upsertStreamRequest.overrideDemeterCalculationEngineGuid) {
                        initialValorizationStreamRunRequests = {
                            ...props.valorizationStreamRunRequest,
                            ...currentStreamModel?.calculationEngine,
                            monthlyValueOverrides: [],
                            overrideName: props.upsertStreamRequest?.overrideName,
                            overrideDemeterCalculationEngineGuid: props.upsertStreamRequest?.overrideDemeterCalculationEngineGuid,
                            valueOverrides: {},
                        };

                        initialValorizationUpsertRequest = {
                            ...props.valorizationUpsertRequest,
                            overrideName: props.upsertStreamRequest?.overrideName,
                            overrideDemeterCalculationEngineGuid: props.upsertStreamRequest?.overrideDemeterCalculationEngineGuid!,
                            valueOverrides: {},
                        };
                    }

                    props.handleUpdateValorizationStreamRunRequests(initialValorizationStreamRunRequests as ValorizationStreamRunRequest);
                    props.handleUpdateValorizationUpsertRequest(initialValorizationUpsertRequest as ValorizationUpsertRequest);

                    setShowUpdateSteamsModal(false);
                }}
            >
                <>
                    <div className={styles.valorization_action_content_row_full_width}>
                        <p>{translations.calculators.valorization.text.resetCustomValues}</p>
                    </div>
                    <div className={styles.valorization_action_content_row_full_width}>
                        <TextInput
                            title={translations.calculators.valorization.fields.streamName}
                            placeholder={translations.calculators.valorization.text.enterName}
                            value={props.upsertStreamRequest?.overrideName ?? props.upsertStreamRequest.name}
                            handleTextChange={(value) => {
                                props.setUpsertStreamRequest({ ...props.upsertStreamRequest!, overrideName: value });
                            }}
                            required
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                        />
                    </div>
                    <div className={styles.valorization_action_content_row}>
                        <Dropdown
                            label={translations.calculators.valorization.text.stream}
                            value={props.upsertStreamRequest?.overrideDemeterCalculationEngineGuid ?? props.upsertStreamRequest.demeterCalculationEngineGuid}
                            options={props.upsertStreamOptions}
                            handleOptionChange={(value) => {
                                props.setUpsertStreamRequest({ ...props.upsertStreamRequest!, overrideDemeterCalculationEngineGuid: value });
                            }}
                        />
                    </div>
                </>
            </ActionModal>
        </>
    );
};

export default ValorizationEditStreamAction;
