const FileExtension = {
    Json: '.json',
    Html: '.html',
    Txt: '.txt',
    Xml: '.xml',
    Csv: '.csv',
    Xlsx: '.xlsx',
};

export default FileExtension;
