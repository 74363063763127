/* eslint-disable class-methods-use-this */
import { IChartAreaRangeData, IChartData } from '../../ChartDefinitions';

class MarketIndicatorDecileChartService {
    calculateDecilesData = (
        data: IChartData[],
        yearsBack: number,
        oneYearWeightedPercent?: number | undefined,
    ): { [decileRank: string]: IChartAreaRangeData[] } => {
        if (oneYearWeightedPercent) {
            const decilesData: { [decile: string]: IChartAreaRangeData[] } = {};
            const innerWeightPercent = oneYearWeightedPercent;
            const outerWeightPercent = 100 - oneYearWeightedPercent;
            data.forEach((currentData, currentIndex) => {
                const currentDate = new Date(currentData.asOfDate);
                const innerStartDate = new Date(currentDate);
                const outerStartDate = new Date(currentDate);
                innerStartDate.setFullYear(currentDate.getFullYear() - 1);
                outerStartDate.setFullYear(currentDate.getFullYear() - yearsBack);

                const innerStartIndex = data.findIndex((d) => d.asOfDate >= innerStartDate);
                const outerStartIndex = data.findIndex((d) => d.asOfDate >= outerStartDate);

                const innerData = data.slice(innerStartIndex, currentIndex).map((item) => item.value);
                const outerData = data.slice(outerStartIndex, currentIndex).map((item) => item.value);

                if (innerData.length > 0 && outerData.length > 0) {
                    const innerDeciles = this.calculateDeciles(innerData);
                    const outerDeciles = this.calculateDeciles(outerData);

                    for (let i = 0; i < 10; i += 1) {
                        const innerDecile = innerDeciles[i];
                        const outerDecile = outerDeciles[i];

                        const decileRank = i + 1;
                        if (!decilesData[decileRank]) {
                            decilesData[decileRank] = [];
                        }
                        decilesData[decileRank].push({
                            asOfDate: currentData.asOfDate,
                            minimumValue: (outerDecile.minValue * outerWeightPercent) / 100 + (innerDecile.minValue * innerWeightPercent) / 100,
                            maximumValue: (outerDecile.maxValue * outerWeightPercent) / 100 + (innerDecile.maxValue * innerWeightPercent) / 100,
                            isActualValue: currentData.isActualValue,
                        } as IChartAreaRangeData);
                    }
                }
            });
            return decilesData;
        }
        const decilesData: { [decile: string]: IChartAreaRangeData[] } = {};
        data.forEach((currentData, currentIndex) => {
            const currentDate = new Date(currentData.asOfDate);
            const outerStartDate = new Date(currentDate);
            outerStartDate.setFullYear(currentDate.getFullYear() - yearsBack);

            const outerStartIndex = data.findIndex((d) => d.asOfDate >= outerStartDate);

            const outerData = data.slice(outerStartIndex, currentIndex).map((item) => item.value);

            if (outerData.length > 0) {
                const deciles = this.calculateDeciles(outerData);
                deciles.forEach((decile, index) => {
                    const decileRank = index + 1;
                    if (!decilesData[decileRank]) {
                        decilesData[decileRank] = [];
                    }
                    decilesData[decileRank].push({
                        asOfDate: currentData.asOfDate,
                        minimumValue: decile.minValue,
                        maximumValue: decile.maxValue,
                        isActualValue: currentData.isActualValue,
                    } as IChartAreaRangeData);
                });
            }
        });
        return decilesData;
    };

    calculateDeciles = (priceArray: number[]): { minValue: number; maxValue: number }[] => {
        const deciles: { minValue: number; maxValue: number }[] = [];
        const sortedArray = priceArray.sort((a, b) => a - b);
        for (let i = 0; i < 10; i += 1) {
            const minValue = this.percentile(sortedArray, i * 10);
            const maxValue = this.percentile(sortedArray, (i + 1) * 10);
            deciles.push({ minValue, maxValue });
        }
        return deciles;
    };

    percentile = (sortedArr: number[], percentile: number): number => {
        const rank = (percentile / 100) * (sortedArr.length + 1);
        if (rank < 1) {
            return sortedArr[0];
        }
        if (rank >= sortedArr.length) {
            return sortedArr[sortedArr.length - 1];
        }
        const lowerIndex = Math.floor(rank) - 1;
        const upperIndex = lowerIndex + 1;
        const weight = rank - Math.floor(rank);
        return sortedArr[lowerIndex] * (1 - weight) + sortedArr[upperIndex] * weight;
    };
}

const marketIndicatorDecileChartService = new MarketIndicatorDecileChartService();
export default marketIndicatorDecileChartService;
