import { DemeterPermissionType } from '../../../../Generated/Raven-Demeter';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';

export type SubPermissionDefinition = {
    title: string;
    key: string;
    hasPermission: boolean;
};

export type PermissionDefinition = {
    titleGetter: () => string;
    permissionType: DemeterPermissionType;
    isVisible: boolean;
    isDisabled?: boolean;
    requiresAdministratorUser?: boolean;
    requiredAnyPermissionTypeToEdit?: DemeterPermissionType[];
    hasPermission: boolean;
    subPermissions?: SubPermissionDefinition[];
};

export const defaultPermissionDefinitions: PermissionDefinition[] = [
    {
        titleGetter: () => `${languageService.translate(translationKeys.risk.headers.budget)} ${languageService.translate(translationKeys.page.calculators)}`,
        permissionType: DemeterPermissionType.BudgetCalculator,
        isVisible: true,
        hasPermission: true,
    },
    {
        titleGetter: () =>
            `${languageService.translate(translationKeys.risk.headers.positions)} ${languageService.translate(translationKeys.page.calculators)}`,
        permissionType: DemeterPermissionType.PositionsCalculator,
        isVisible: true,
        hasPermission: true,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.marketIndicators.title),
        permissionType: DemeterPermissionType.MarketIndicators,
        isVisible: false,
        requiredAnyPermissionTypeToEdit: [DemeterPermissionType.MarketIndicatorAdministrator],
        hasPermission: false,
        subPermissions: [],
    },
    {
        titleGetter: () => languageService.translate(translationKeys.marketIndicators.hedgeMontiorPro),
        permissionType: DemeterPermissionType.HedgeMonitorPro,
        isVisible: false,
        requiredAnyPermissionTypeToEdit: [DemeterPermissionType.HedgeMonitorProAdministrator],
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.users),
        permissionType: DemeterPermissionType.Users,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.usersExport),
        permissionType: DemeterPermissionType.UsersExport,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.hedgeMonitorProAdministrator),
        permissionType: DemeterPermissionType.HedgeMonitorProAdministrator,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.valorization),
        permissionType: DemeterPermissionType.Valorization,
        isVisible: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.marketIndicatorAdministrator),
        permissionType: DemeterPermissionType.MarketIndicatorAdministrator,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.marketIndicatorCompliance),
        permissionType: DemeterPermissionType.MarketIndicatorCompliance,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.marketIndicatorDeveloper),
        permissionType: DemeterPermissionType.MarketIndicatorDeveloper,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
    },
    {
        titleGetter: () => languageService.translate(translationKeys.permissions.text.marketIndicatorSubjectMatterExpert),
        permissionType: DemeterPermissionType.MarketIndicatorSubjectMatterExpert,
        isVisible: false,
        requiresAdministratorUser: true,
        hasPermission: false,
        subPermissions: [],
    },
];
