import { useMemo, useState } from 'react';
import { ExchangeType } from '../../../../Generated/Raven-Demeter';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { EventDataTargetsEnum } from '../../../Services/Logging/DataLayerDefinitions';
import { ITreeNode } from '../../TreeView/TreeItem';
import ManageProducts from './ManageProducts';
import styles from './ProductsModal.module.scss';
import ProductsModalWrapper from './ProductsModalWrapper';

export interface CommodityReferenceKey {
    commodity?: string;
    displayName: string;
    guid: string;
}

interface IManageFuturesModal {
    title: string;
    selected: CommodityReferenceKey[];
    open: boolean;
    exchange: ExchangeType;
    handleChange: (selectedOptions: CommodityReferenceKey[]) => void;
    handleClose: () => void;
}

const ManageFuturesModal: React.FC<IManageFuturesModal> = (props) => {
    const [translations] = useLanguage();
    const [symbolNodesSelected, setSymbolNodesSelected] = useState<ITreeNode[]>([]);
    const symbols = useSymbolsApi();

    const treeNodes = useMemo<ITreeNode[]>(() => {
        if (!symbols?.length) {
            return [];
        }

        return symbols
            .filter((x) => x.exchange === props.exchange)
            .map((symbol, index) => {
                const item: ITreeNode = {
                    id: symbol.commodity,
                    value: `root_${symbol.symbolCategory}_${symbol.displayName}`, // Path of this node realitive to the root.
                    name: languageService.toDisplayName(symbol),
                    parentValue: `${symbol.symbolCategory}`, // Parent node for this node to point to.
                    parentId: `${symbol.symbolCategory}`,
                    column: props.selected.some((option) => option.commodity === symbol.commodity) ? 'right' : 'left',
                    displayOrder: props.selected.findIndex((x) => x.commodity === symbol.commodity),
                    order: index,
                    expanded: false,
                    selectedProduct: false,
                    children: [],
                };

                return item;
            });
    }, [symbols, props.selected, props.open]);

    const setSelectedSymbols = () => {
        if (!symbols?.length && !symbolNodesSelected.length) {
            props.handleChange([]);
            props.handleClose();
            return;
        }

        const allIndicators = symbols!
            .filter((x) => x.exchange === props.exchange)
            .map((x) => ({ guid: x.reutersInstrumentCodePrefix, commodity: x.commodity, displayName: x.displayName }));
        const selectedSymbols: CommodityReferenceKey[] = symbolNodesSelected
            .filter((node) => allIndicators.some((x) => x.commodity === node.id))
            .map((node) => {
                const symbolMatch = allIndicators.find((x) => x.commodity === node.id);
                return symbolMatch!;
            });

        props.handleChange(selectedSymbols);
        props.handleClose();
    };

    return (
        <ProductsModalWrapper
            name="FuturesProductModal"
            dataTarget={EventDataTargetsEnum.MangeModal}
            title={props.title}
            open={props.open}
            isSetButtonDisabled={false}
            subTitle={translations.dashboard.indicators.manageModalSubHeading}
            handleClose={props.handleClose}
            handleSave={setSelectedSymbols}
        >
            <div className={styles.products_modal_content}>
                <ManageProducts
                    productTreeNodes={treeNodes}
                    showSingleOrGroupOption={false}
                    handleSelectedUpdate={setSymbolNodesSelected}
                    searchPlaceholder={translations.futures.text.searchProductPlaceholder}
                    availableSubHeader={translations.dashboard.indicators.availableProducts}
                    selectSubHeader={translations.dashboard.indicators.selectedProducts}
                />
            </div>
        </ProductsModalWrapper>
    );
};

export default ManageFuturesModal;
