import { useEffect, useState } from 'react';
import LinkButton, { LinkButtonType } from '../../../../../Components/Form/Buttons/LinkButton';
import TextInput from '../../../../../Components/Form/Inputs/TextInput';
import ActionModal from '../../../../../Components/Modals/ActionModal/ActionModal';
import useSearchParameters from '../../../../../Components/Navigation/Hooks/useSearchParametersHook';
import RegexValidators from '../../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../../Services/Language/useLanguageHook';
import { BaseValorizationUpsertRequest, ValorizationUpsertRequest } from '../../ValorizationCalculatorDefinitions';
import styles from '../ValorizationCalculatorActions.module.scss';

interface IValorizationSaveAsActionProps {
    valorizationUpsertRequests: ValorizationUpsertRequest[];
    baseValorizationUpsertRequest: BaseValorizationUpsertRequest;
    setBaseValorizationUpsertRequest: (baseValorizationUpsertRequest: BaseValorizationUpsertRequest) => void;
    handleUpsertValorizationRequests: () => void;
    handleUpdateValorizationUpsertRequest: (valorizationUpsertRequest: ValorizationUpsertRequest, isBaseRequest?: boolean) => void;
}

const ValorizationSaveAsAction: React.FC<IValorizationSaveAsActionProps> = (props: IValorizationSaveAsActionProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();

    // Modal display hook.
    const [showSaveValorizationModelAsModalOpen, setShowSaveValorizationModelAsModalOpen] = useState(false);

    // Only call the add apis after updating the requests (after save button is hit and update complete).
    useEffect(() => {
        if (!showSaveValorizationModelAsModalOpen) {
            return;
        }

        handleResetGroupNameAndCloseModal();
        props.handleUpsertValorizationRequests();
    }, [props.valorizationUpsertRequests]);

    const handleResetGroupNameAndCloseModal = () => {
        props.setBaseValorizationUpsertRequest({ ...props.baseValorizationUpsertRequest!, groupName: '' });
        setShowSaveValorizationModelAsModalOpen(false);
    };

    return (
        <>
            <div
                className={
                    searchParameters.groupName === 'default'
                        ? styles.valorization_action_selection_area_save_as_button_alone
                        : styles.valorization_action_selection_area_save_as_button
                }
            >
                <LinkButton
                    title={`${translations.text.saveAs}...`}
                    type={LinkButtonType.White}
                    onClick={() => setShowSaveValorizationModelAsModalOpen(true)}
                />
            </div>
            <ActionModal
                header={translations.calculators.valorization.text.setValorizationModelAs}
                showModal={showSaveValorizationModelAsModalOpen}
                handleCancel={handleResetGroupNameAndCloseModal}
                actionButtonName={translations.actions.save}
                handleAction={() => {
                    props.handleUpdateValorizationUpsertRequest(
                        { groupName: props.baseValorizationUpsertRequest.groupName } as ValorizationUpsertRequest,
                        true,
                    );
                }}
                actionButtonDisabled={!props.baseValorizationUpsertRequest?.groupName || props.baseValorizationUpsertRequest?.groupName === 'default'}
            >
                <TextInput
                    title={translations.words.name}
                    placeholder={translations.calculators.valorization.text.enterName}
                    required
                    value={props.baseValorizationUpsertRequest?.groupName}
                    handleTextChange={(value) => {
                        props.setBaseValorizationUpsertRequest({ ...props.baseValorizationUpsertRequest!, groupName: value });
                    }}
                    validation={RegexValidators.AlphaNumericMinimumLength2}
                />
            </ActionModal>
        </>
    );
};

export default ValorizationSaveAsAction;
